import { EmptyRespone } from '../../../domain/common/EmptyRespone';
import { CreateProjectBody } from '../../../domain/projects/models/CreateProjectBody';
import { Project } from '../../../domain/projects/models/Project';
import { ProjectsRepository } from '../../../domain/projects/repository/ProjectsRepository';
import { AddUserBody } from '../../../domain/users/models/AddUserBody';
import NewtorkService from '../NetworkService';

class ProjectsService extends NewtorkService implements ProjectsRepository {

    createNewProject(createProjectBody: CreateProjectBody): Promise<Project> {
        return this.request('/projects', 'POST', createProjectBody);
    }

    getAllProjects(): Promise<Project[]> {
        return this.request('/projects', 'GET');
    }

    addUserInProject(projectId: string, addUserBody: AddUserBody): Promise<EmptyRespone> {
        return this.request(`/projects/${projectId}/users`, 'POST', addUserBody);
    }

    removeUserFromProject(projectId: string, userId: string): Promise<EmptyRespone> {
        return this.request(`/projects/${projectId}/users/${userId}`, 'DELETE');
    }

    deleteProject(projectId: string): Promise<EmptyRespone> {
        return this.request(`/projects/${projectId}`, 'DELETE');
    }
}

export const projectsService = new ProjectsService();