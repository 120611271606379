import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { authService } from '../data/network/services/AuthService';
import { LoginBody } from '../domain/auth/models/LoginBody';
import { dataStore } from '../data/dataStore/WebStorageDataStore';

interface AuthSliceState {
    isAuth: boolean;
}

const initialState: AuthSliceState = {
    isAuth: dataStore.accessToken ? true : false
};

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {

    },
    extraReducers: (builder) => {
        builder.addCase(login.fulfilled, (state) => {
            state.isAuth = true;
        });
        builder.addCase(logout.fulfilled, (state) => {
            state.isAuth = false;
        });
    }
});

export const login = createAsyncThunk(
    'auth/login',
    async function (loginBody: LoginBody) {
        await authService.login(loginBody);
    }
);

export const logout = createAsyncThunk(
    'auth/logout',
    async function () {
        dataStore.accessToken = null;
        dataStore.refreshToken = null;
    }
);

export default authSlice.reducer;