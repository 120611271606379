import { PayloadAction, createSlice, isFulfilled, isPending, isRejected } from '@reduxjs/toolkit';
import { login } from './AuthSlice';
import { addUserInGroup, createNewGroup, deleteGroup, editUsersInGroup, getGroups, removeUserFromGroup } from './GroupsSlice';
import { createNewProject, deleteProject, editUsersInProject, getAllProjects } from './ProjectsSlice';
import { createNewQuestion, editQuestion } from './QuestionsSlice';
import { createNewRepository, deleteRepository } from './RepositoriesSlice';
import { createNewSurvey, getAllSurveys, editSurvey, deleteSurvey, launchSurvey, postSurveyAnswersBody } from './SurveysSlice';
import { getUsers, createNewUser, deleteUser, editUser } from './UsersSlice';
import { NotificationProps } from '../domain/common/NotificationProps';
import { getStatisticBySurveyId } from './StatisticSlice';

interface NotificationSliceState {
    notification?: NotificationProps;
    isLoading: boolean;
}

const initialState: NotificationSliceState = {
    notification: undefined,
    isLoading: false
};

const allAsyncThunks = [
    login,
    createNewGroup,
    getGroups,
    deleteGroup,
    addUserInGroup,
    removeUserFromGroup,
    createNewProject,
    getAllProjects,
    createNewQuestion,
    editQuestion,
    createNewRepository,
    deleteRepository,
    createNewSurvey,
    getAllSurveys,
    editSurvey,
    deleteSurvey,
    launchSurvey,
    postSurveyAnswersBody,
    getUsers,
    createNewUser,
    editUsersInProject,
    deleteUser,
    editUser,
    deleteProject,
    getStatisticBySurveyId
] as const;

export const notificationSlice = createSlice({
    name: 'notification',
    initialState,
    reducers: {
        setNotification(state, action: PayloadAction<NotificationProps | undefined>) {
            state.notification = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(createNewRepository.fulfilled, (state) => {
            state.notification = {
                notificationBody: 'Репозиторий успешно создан',
                notificationType: 'success'
            };
        });
        builder.addCase(deleteRepository.fulfilled, (state) => {
            state.notification = {
                notificationBody: 'Репозиторий удалён',
                notificationType: 'success'
            };
        });
        builder.addCase(editUsersInProject.fulfilled, (state, action) => {
            state.notification = action.payload.notification;
        });
        builder.addCase(createNewProject.fulfilled, (state) => {
            state.notification = {
                notificationBody: 'Проект создан',
                notificationType: 'success'
            };
        });
        builder.addCase(createNewGroup.fulfilled, (state) => {
            state.notification = {
                notificationBody: 'Группа создана',
                notificationType: 'success'
            };
        });
        builder.addCase(deleteGroup.fulfilled, (state) => {
            state.notification = {
                notificationBody: 'Группа удалена',
                notificationType: 'success'
            };
        });
        builder.addCase(editUsersInGroup.fulfilled, (state, action) => {
            state.notification = action.payload.notification;
        });
        builder.addCase(createNewSurvey.fulfilled, (state) => {
            state.notification = {
                notificationBody: 'Опросник создан',
                notificationType: 'success'
            };
        });
        builder.addCase(editSurvey.fulfilled, (state) => {
            state.notification = {
                notificationBody: 'Опросник отредактирован',
                notificationType: 'success'
            };
        });
        builder.addCase(deleteSurvey.fulfilled, (state) => {
            state.notification = {
                notificationBody: 'Опросник удалён',
                notificationType: 'success'
            };
        });
        builder.addCase(createNewUser.fulfilled, (state) => {
            state.notification = {
                notificationBody: 'Пользователь создан',
                notificationType: 'success'
            };
        });
        builder.addCase(editUser.fulfilled, (state) => {
            state.notification = {
                notificationBody: 'Пользователь отредактирован',
                notificationType: 'success'
            };
        });
        builder.addCase(deleteUser.fulfilled, (state) => {
            state.notification = {
                notificationBody: 'Пользователь удалён',
                notificationType: 'success'
            };
        });
        builder.addCase(deleteProject.fulfilled, (state) => {
            state.notification = {
                notificationBody: 'Проект удалён',
                notificationType: 'success'
            };
        });

        builder.addMatcher(isPending(...allAsyncThunks), (state) => {
            state.isLoading = true;
        });
        builder.addMatcher(isFulfilled(...allAsyncThunks), (state, action) => {
            state.isLoading = false;
        });
        builder.addMatcher(isRejected(...allAsyncThunks), (state, { error }) => {
            state.isLoading = false;
            state.notification = {
                notificationBody: error.message || '',
                notificationType: 'error'
            };
        });

    }
});

export const { setNotification } = notificationSlice.actions;

export default notificationSlice.reducer;