import './App.scss';
import { Layout, Menu, Spin, Typography } from 'antd';
import { Routes, Route, Link, useNavigate, useLocation } from 'react-router-dom';
import Users from './views/users/Users';
import { useAppDispatch, useAppSelector } from './hooks/redux-hooks';
import Auth from './views/auth/Auth';
import type { MenuProps } from 'antd';
import Projects from './views/projects/Projects';
import Notification from './views/components/notification/Notification';
import { useEffect } from 'react';
import { setNotification } from './slices/NotificationSlice';
import Survey from './views/survey/Survey';
import { logout } from './slices/AuthSlice';
import { ReactComponent as TsuLogo } from './views/common/resources/images/tsu_logo_small.svg';
import { Statisctic } from './views/statisctic/Statistic';

const { Content, Header } = Layout;
const { Text } = Typography;

function App() {

    const { isAuth } = useAppSelector(state => state.authReducer);
    const { isLoading, notification } = useAppSelector(state => state.notificationReducer);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        if (notification) {
            Notification.handle(notification);
            dispatch(setNotification(undefined));
        }
    }, [notification]);

    const menuItems: MenuProps['items'] = [
        {
            label: <Link to="/users">Пользователи</Link>,
            key: '/users'
        },
        {
            label: <Link to="/projects">Проекты</Link>,
            key: '/projects'
        },
        {
            label: 'Выйти',
            onClick: (() => dispatch(logout())),
            style: { marginLeft: 'auto' },
            key: 'exit'
        }
    ];

    if (!isAuth) {
        return (
            <Layout>
                <Content className="main-content">
                    <Spin spinning={isLoading}>
                        <Routes>
                            <Route path="*" element={<Auth />} />
                            <Route path="/survey" element={<Survey />} />
                        </Routes>
                    </Spin>
                </Content>
            </Layout>
        );
    }

    return (

        <Layout>
            <Header className="daily-bot-header">
               
                <span className="header-logo" onClick={() => navigate('/')}>
                    <TsuLogo width={40} height={40} style={{ marginLeft: '4px' }} />
                    <Text>
                        <span>InTime</span> <br />
                        <span>DailyBot</span>
                    </Text>
                </span>
                
                <Menu theme="dark" mode="horizontal" items={menuItems} selectedKeys={[location.pathname]} />
            </Header>

            <Content className="main-content">
                <Spin spinning={isLoading}>
                    <Routes>
                        <Route path="/" element={<Statisctic/>} />
                        <Route path="/users" element={<Users />} />
                        <Route path="/projects" element={<Projects />} />
                        <Route path="/survey" element={<Survey />} />
                    </Routes>
                </Spin>
            </Content>
        </Layout >
    );
}

export default App;
