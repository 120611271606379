import { PlusOutlined } from '@ant-design/icons';
import { Button, Divider, Form, Input, Typography } from 'antd';
import { useState } from 'react';
import { User } from '../../../domain/users/models/User';
import { useAppSelector } from '../../../hooks/redux-hooks';
import EditableList from '../../components/editableList/EditableList';
import { SurveyFormItems } from './SurveyFormItems';

const { Title } = Typography;



export function NewGroupFormItems() {

    const { users } = useAppSelector(state => state.usersReducer);
    const [selectedUsers, setSelectedUsers] = useState<User[]>([]);

    function addToSelectedUsers(user: User) {
        if (!selectedUsers.some(usr => usr.id === user.id)) {
            setSelectedUsers([...selectedUsers, user]);
        }
    }

    function removeFromSelectedUsers(user: User) {
        setSelectedUsers([...selectedUsers.filter(usr => usr.id !== user.id)]);
    }


    return (
        <>
            <Form.Item
                label="Название группы"
                name={['name']}
                rules={[{ message: 'Введите название для группы', required: true, whitespace: true }]}
            >
                <Input />
            </Form.Item>

            <Divider />


            <EditableList
                className="editable-list-users"
                title="Выбранные пользователи:"
                selectedItems={selectedUsers}
                allItems={users || []}

                renderSelectedListItem={(item, index) => {
                    return <Form.Item
                        name={['selectedUsers', index, 'userId']}
                        initialValue={item.id}
                        noStyle
                    >
                        {item.lastName} {item.firstName} {item.middleName}
                    </Form.Item>;
                }}

                renderAllListItem={(item) => {
                    return <>{item.lastName} {item.firstName} {item.middleName}</>;
                }}

                searchFunction={(users, filter) => {

                    return users.filter(user => {
                        const trimAndLowerCaseFullname = `${user.lastName} ${user.firstName} ${user.middleName}`.trim().toLowerCase();
                        const trimAndLowerCaseFilter = filter.trim().toLowerCase();
                        
                        return trimAndLowerCaseFullname.includes(trimAndLowerCaseFilter);
                    });
                }}

                insertInArray={addToSelectedUsers}
                removeFromArray={removeFromSelectedUsers}
            />


            <Divider />
            <Form.List
                name={['surveys']}
            >
                {(fields, { add, remove }) => {

                    return (
                        <>
                            <Title level={4}>
                                Опросники
                                <Button
                                    type="primary"
                                    onClick={() => add()}
                                    icon={<PlusOutlined />}
                                    style={{ marginLeft: '16px' }}
                                />
                            </Title>
                            {fields.map(({ key, name, ...restField }) => (

                                <SurveyFormItems
                                    key={key}
                                    name={name}
                                    restField={restField}
                                    remove={remove}
                                />
                            ))}
                        </>
                    );
                }}
            </Form.List>

            <Divider />
        </>
    );
}