import { PlusOutlined } from '@ant-design/icons';
import { Form, Input, Divider, Button, Typography } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { useState, useEffect } from 'react';
import { Survey } from '../../../domain/surveys/models/Survey';
import { User } from '../../../domain/users/models/User';
import { editUsersInGroup } from '../../../slices/GroupsSlice';
import { editSurveysInGroup, getAllSurveys } from '../../../slices/SurveysSlice';
import EditableList from '../../components/editableList/EditableList';
import { SurveyFormItems } from './SurveyFormItems';
import { Group } from '../../../domain/groups/models/Group';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux-hooks';

const { Title } = Typography;

interface EditGroupFormProps {
    selectedProjectId: string;
    selectedGroup?: Group;
    closeModal: () => void;
}

export function EditGroupForm(props: EditGroupFormProps) {
    const { selectedProjectId, selectedGroup } = props;

    const { surveys } = useAppSelector(state => state.surveysReducer);
    const { users } = useAppSelector(state => state.usersReducer);

    const dispatch = useAppDispatch();

    const [selectedUsers, setSelectedUsers] = useState<User[]>(selectedGroup ? selectedGroup.users : []);
    const [initialSurveys, setInitialSurveys] = useState<Survey[]>([]);
    const [editGroupForm] = useForm();

    useEffect(() => {
        if (surveys === undefined) {
            dispatch(getAllSurveys());
        }

    }, []);

    useEffect(() => {

        if (surveys) {
            const initSurveys = surveys.filter(survey => survey.groupId === selectedGroup?.id);
            setInitialSurveys(initSurveys);
            editGroupForm.setFieldValue(['surveys'], initSurveys);
        }

    }, [surveys]);


    function editGroupHandler(value: { name: string, surveys: Survey[], }) {


        if (value.surveys) {
            dispatch(editSurveysInGroup({ groupId: selectedGroup!.id, newSurveys: value.surveys, oldSurveys: initialSurveys }));
        }

        dispatch(editUsersInGroup(
            {
                projectId: selectedProjectId,
                groupId: selectedGroup!.id,
                selectedUsers: selectedUsers,
                currentUsers: selectedGroup!.users
            }
        ));

        props.closeModal();
    }

    function addToSelectedUsers(user: User) {
        setSelectedUsers([...selectedUsers, user]);
    }

    function removeFromSelectedUsers(user: User) {
        setSelectedUsers(selectedUsers.filter(usr => usr.id !== user.id));
    }

    return (


        <Form
            layout="vertical"
            onFinish={(value) => editGroupHandler(value)}
            form={editGroupForm}

        >
            <Form.Item
                label="Название группы"
                name="name"
                initialValue={selectedGroup?.name}
                rules={[{ message: '', required: true, whitespace: true }]}
            >
                <Input disabled />
            </Form.Item>

            <Divider />

            <EditableList
                className="editable-list-users"
                title="Выбранные пользователи:"
                selectedItems={selectedUsers}
                allItems={users || []}

                renderSelectedListItem={(item) => {
                    return <Form.Item
                        name={['users', item.id]}
                        initialValue={item.id}
                        noStyle
                    >
                        {item.lastName} {item.firstName} {item.middleName}
                    </Form.Item>;
                }}


                renderAllListItem={(item) => {
                    return <>{item.lastName} {item.firstName} {item.middleName}</>;
                }}

                searchFunction={(users, filter) => {

                    return users.filter(user => {
                        const trimAndLowerCaseFullname = `${user.lastName} ${user.firstName} ${user.middleName}`.trim().toLowerCase();
                        const trimAndLowerCaseFilter = filter.trim().toLowerCase();

                        return trimAndLowerCaseFullname.includes(trimAndLowerCaseFilter);
                    });
                }}

                insertInArray={addToSelectedUsers}
                removeFromArray={removeFromSelectedUsers}
            />
            <Form.List
                name="surveys"
            >
                {(fields, { add, remove }) => {

                    return (
                        <>
                            <Title level={4}>
                                Опросники
                                <Button
                                    type="primary"
                                    onClick={() => add()}
                                    icon={<PlusOutlined />}
                                    style={{ marginLeft: '16px' }}
                                />
                            </Title>
                            {fields.map(({ key, name, ...restField }) => (

                                <SurveyFormItems
                                    key={key}
                                    name={name}
                                    restField={restField}
                                    remove={remove}
                                />
                            ))}
                        </>
                    );
                }}
            </Form.List>

            <Form.Item
                noStyle
            >
                <Button
                    type="primary"
                    htmlType="submit"
                    block
                >
                    Сохранить изменения
                </Button>
            </Form.Item>
        </Form>

    );
}