import { combineReducers, configureStore } from '@reduxjs/toolkit';
import groupsReducer from '../slices/GroupsSlice';
import projectsReducer from '../slices/ProjectsSlice';
import questionsReducer from '../slices/QuestionsSlice';
import repositoriesReducer from '../slices/RepositoriesSlice';
import surveysReducer from '../slices/SurveysSlice';
import usersReducer from '../slices/UsersSlice';
import authReducer from '../slices/AuthSlice';
import notificationReducer from '../slices/NotificationSlice';
import statisticReducer from '../slices/StatisticSlice';


const rootReducer = combineReducers({
    notificationReducer,
    groupsReducer,
    projectsReducer,
    questionsReducer,
    repositoriesReducer,
    surveysReducer,
    usersReducer,
    authReducer,
    statisticReducer
});

export const setupStore = () => {
    return configureStore({
        reducer: rootReducer,
        middleware: (getDefaultMiddleware) => getDefaultMiddleware({
            serializableCheck: false
        }),
    });
};

export type RootState = ReturnType<typeof rootReducer>;

export type AppStore = ReturnType<typeof setupStore>;

export type AppDispatch = AppStore['dispatch'];