import { Button, Card, Checkbox, Divider, Form, Input, Typography } from 'antd';
import React from 'react';
import { LoginBody } from '../../domain/auth/models/LoginBody';
import { useAppDispatch } from '../../hooks/redux-hooks';
import { login } from '../../slices/AuthSlice';
import './auth.scss';
import { ReactComponent as TsuLogo } from '../common/resources/images/tsu_logo_small.svg';

const { Title, Text } = Typography;

export default function Auth() {

    const dispatch = useAppDispatch();

    function loginHandler(loginBody: LoginBody) {
        dispatch(login(loginBody));
    }

    return (
        <div className="auth-wrapper">
            <Card className="auth-card">
                <span className="logo">
                    <TsuLogo width={80} height={80} />
                    <Text >
                        <Title level={2}>InTime</Title> <br />
                        <Title level={3}>DailyBot</Title>
                    </Text>
                </span>
                <Divider />
                <Title level={3}>Войти</Title>
                <Form
                    layout="vertical"
                    onFinish={loginHandler}
                >
                    <Form.Item
                        label="Электронная почта"
                        name="email"
                        rules={[{ message: '', required: true, type: 'email' }]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="Пароль"
                        name="password"
                        rules={[
                            { message: '', required: true },
                            { message: 'Пароль должен содержать как минимум 8 символов', min: 8 }
                        ]}
                    >
                        <Input.Password autoComplete="on" />
                    </Form.Item>

                    <Form.Item

                        name="rememberMe"
                        valuePropName="checked"
                        initialValue={false}

                    >
                        <Checkbox>
                        Запомнить меня
                        </Checkbox>
                    </Form.Item>
                    <Form.Item
                        noStyle
                    >
                        <Button
                            type="primary"
                            htmlType="submit"
                            block
                        >
                        Войти
                        </Button>
                    </Form.Item>
                </Form>
            </Card>
        </div>
    );
}