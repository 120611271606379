import { Form, Button } from 'antd';
import { useState } from 'react';
import { Project } from '../../../domain/projects/models/Project';
import { User } from '../../../domain/users/models/User';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux-hooks';
import { editUsersInProject } from '../../../slices/ProjectsSlice';
import EditableList from '../../components/editableList/EditableList';

export function EditUsersListForm(props: { selectedProject: Project, }) {
    const { selectedProject } = props;
    const dispatch = useAppDispatch();

    const { users } = useAppSelector(state => state.usersReducer);
    const [selectedUsers, setSelectedUsers] = useState<User[]>(selectedProject.users);

    function editUsersInProjectHandler(selectedUsers: User[]) {
        dispatch(editUsersInProject(
            {
                projectId: selectedProject.id,
                oldUsers: selectedProject.users,
                newUsers: selectedUsers
            }
        ));
    }

    function addToSelectedUsers(user: User) {
        if (!selectedUsers.some(usr => usr.id === user.id)) {
            setSelectedUsers([...selectedUsers, user]);
        }
    }

    function removeFromSelectedUsers(user: User) {
        setSelectedUsers([...selectedUsers.filter(usr => usr.id !== user.id)]);
    }

    return (
        <Form
            layout="vertical"
            onFinish={() => editUsersInProjectHandler(selectedUsers)}
        >
            <EditableList
                className="editable-list-users"
                title="Выбранные пользователи:"
                selectedItems={selectedUsers}
                allItems={users || []}

                renderSelectedListItem={(item) => {
                    return <>{item.lastName} {item.firstName} {item.middleName}</>;
                }}

                renderAllListItem={(item) => {
                    return <>{item.lastName} {item.firstName} {item.middleName}</>;
                }}

                searchFunction={(users, filter) => {

                    return users.filter(user => {
                        const trimAndLowerCaseFullname = `${user.lastName} ${user.firstName} ${user.middleName}`.trim().toLowerCase();
                        const trimAndLowerCaseFilter = filter.trim().toLowerCase();

                        return trimAndLowerCaseFullname.includes(trimAndLowerCaseFilter);
                    });
                }}

                insertInArray={addToSelectedUsers}
                removeFromArray={removeFromSelectedUsers}
            />

            <Form.Item
                noStyle
            >
                <Button
                    type="primary"
                    htmlType="submit"
                    block
                >
                    Редактировать список
                </Button>
            </Form.Item>
        </Form>

    );
}