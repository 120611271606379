import { Col, Input, Row, List, Checkbox, Empty } from 'antd';
import { CloseCircleOutlined, SearchOutlined } from '@ant-design/icons';
import './editable-list.scss';
import React, { useState } from 'react';

interface EditableListProps<T extends { id: string, }> {
    title: string;
    selectedItems: Array<T>;
    allItems: Array<T>;
    className?: string;

    searchFunction: (allItems: Array<T>, filter: string) => Array<T>;
    renderSelectedListItem: (item: T, index: number) => React.ReactNode;
    renderAllListItem: (item: T) => React.ReactNode;

    insertInArray: (item: T) => void;
    removeFromArray: (item: T) => void;
}

export default function EditableList<T extends { id: string, }>(props: EditableListProps<T>) {

    const [filter, setFilter] = useState('');

    function changeHandler(e: any, item: T) {

        if (e.target.checked) {
            props.insertInArray(item);
        }
        else {
            props.removeFromArray(item);
        }
    }

    return (

        <Row gutter={16} className={`editable-list-wrapper ${props.className}`}>

            <Col xs={24} md={10} className="list-wrapper">

                <span className="header">
                    <div className="editable-list-title">
                        {props.title}
                    </div>
                </span>
                <List
                    size="small"
                    className="list"
                    bordered
                    dataSource={props.selectedItems}
                    renderItem={
                        (item, index) =>
                            <List.Item
                                extra={
                                    <div className="delete-field-button-wrapper">
                                        <CloseCircleOutlined checked={false} onClick={(e) => {
                                            changeHandler(e, item);
                                        }} />
                                    </div>
                                }
                                className="selected-item"
                            >
                                <div>
                                    {props.renderSelectedListItem(item, index)}
                                </div>

                            </List.Item>
                    }
                    locale={{ emptyText: <Empty description="Пусто" /> }}
                />
            </Col>

            <Col xs={24} md={14} className="list-wrapper">


                <Input
                    className="header"
                    placeholder="Поиск"
                    prefix={<SearchOutlined />}
                    onChange={(changeEvent) => setFilter(changeEvent.currentTarget.value)}
                />



                <List
                    className="list"
                    size="small"
                    bordered
                    dataSource={props.searchFunction(props.allItems, filter)}
                    renderItem={
                        item =>
                            <List.Item
                            >
                                <Checkbox
                                    checked={props.selectedItems.some(selectedItem => selectedItem.id === item.id)}
                                    onChange={(e) => { changeHandler(e, item); }}
                                >
                                    {props.renderAllListItem(item)}
                                </Checkbox>
                            </List.Item>
                    }
                    locale={{ emptyText: <Empty description="Пусто" /> }}
                />



            </Col>
        </Row >
    );

}