import { CloseCircleOutlined, PlusOutlined, DeleteOutlined } from '@ant-design/icons';
import { Popover, Button, Row, Col, Form, Input, Radio, TimePicker, InputNumber, Divider, Typography } from 'antd';
import { useState } from 'react';

const { Title } = Typography;

interface SurveyFormItemsProps {
    name: number;
    restField: { fieldKey?: number | undefined, };
    remove: (index: number | number[]) => void;
}

export function SurveyFormItems(props: SurveyFormItemsProps) {

    type TypeOfSchedule = 'DAILY' | 'WEEKLY';

    const weeks = ['MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY', 'SUNDAY'];
    const weeksRu = ['Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Суббота', 'Воскресенье'];

    const [, setCurrentTypeOfSchedule] = useState<TypeOfSchedule>();
    const [isOpen, setIsOpen] = useState(false);

    const DeleteQuestionPopover = (props: {
        remove: (name: number) => void,
        name: number,
    }) => {

        const [isDeleteQuestionPopoverOpen, setIsDeleteQuestionPopoverOpen] = useState(false);

        return (
            <Popover
                placement="left"
                trigger="click"
                title="Удалить вопрос?"
                open={isDeleteQuestionPopoverOpen}
                onOpenChange={setIsDeleteQuestionPopoverOpen}

                content={
                    <>
                        <Button
                            danger
                            onClick={() => {
                                setIsDeleteQuestionPopoverOpen(false);
                                props.remove(props.name);
                            }}
                        >
                            Да
                        </Button>
                        <Button
                            style={{ marginLeft: '16px' }}
                            onClick={() => setIsDeleteQuestionPopoverOpen(false)}
                        >
                            Нет
                        </Button>
                    </>
                }
            >
                <Button
                    title="Удалить вопрос"
                    type="text"
                    danger
                    icon={<CloseCircleOutlined />}
                />
            </Popover>
        );
    };

    return (
        <div className="new-survey-form">
            <Row >
                <Col span={24}>
                    <Row gutter={16} wrap={false}>
                        <Col flex={1}>
                            <Row gutter={16}>
                                <Col xs={24} md={12}>
                                    <Form.Item
                                        {...props.restField}
                                        label="Заголовок"
                                        name={[props.name, 'title']}
                                        rules={[
                                            { required: true, whitespace: true, message: '' },
                                            { message: 'Заголовок должен содержать как минимум 3 символа', min: 3 }
                                        ]}
                                    >
                                        <Input placeholder="Заголовок" />
                                    </Form.Item>

                                </Col>

                                <Col xs={24} md={6}>
                                    <Form.Item
                                        label="ID канала"
                                        name={[props.name, 'channelId']}
                                        rules={[{ required: true, message: 'Введите ID канала' }]}
                                    >
                                        <InputNumber min={0} style={{ width: '100%' }} stringMode />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>
                        <Col>
                            <Popover
                                placement="left"
                                trigger="click"
                                title="Удалить опросник?"
                                open={isOpen}
                                onOpenChange={setIsOpen}

                                content={
                                    <>
                                        <Button
                                            danger
                                            onClick={() => {
                                                setIsOpen(false);
                                                props.remove(props.name);
                                            }}
                                        >
                                            Да
                                        </Button>
                                        <Button
                                            style={{ marginLeft: '16px' }}
                                            onClick={() => setIsOpen(false)}
                                        >
                                            Нет
                                        </Button>
                                    </>
                                }
                            >
                                <Button
                                    title="Удалить опросник"
                                    type="dashed"
                                    danger
                                    icon={<DeleteOutlined />}
                                />
                            </Popover>
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={24} md={6}>

                            <Form.Item
                                label="Время на ответ (в минутах)"
                                name={[props.name, 'timeToAnswerInMinutes']}
                                rules={[{ required: true, message: 'Введите время на ответ' }]}
                            >
                                <InputNumber placeholder="Время" min={1} />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={24} md={6}>

                            <Form.Item
                                label="Тип расписания"
                                name={[props.name, 'schedule', 'type']}
                                rules={[{ required: true, message: 'Выберите тип расписания' }]}
                            >
                                <Radio.Group
                                    size="small"
                                    buttonStyle="solid"
                                    onChange={(e) => setCurrentTypeOfSchedule(e.target.value)}
                                >
                                    <Radio.Button value="DAILY">Ежедневно</Radio.Button>
                                    <Radio.Button value="WEEKLY">Еженедельно</Radio.Button>
                                </Radio.Group>
                            </Form.Item>


                        </Col>
                    </Row>


                    <Form.Item dependencies={[props.name, 'schedule', 'type']}>

                        {({ getFieldValue }) => {
                            let fieldValue = ['surveys', props.name, 'schedule', 'type'];
                            const currentType = getFieldValue(fieldValue) as TypeOfSchedule;

                            if (currentType === 'DAILY') {

                                return (
                                    <>
                                        <Divider />
                                        <Row>
                                            <Col xs={24} md={6}>
                                                <Form.Item
                                                    name={[props.name, 'schedule', 'sendOutTime']}
                                                    label="Время (HH:MM)"
                                                    rules={[{ required: true, message: '' }]}
                                                >
                                                    <TimePicker
                                                        format="HH:mm"
                                                        style={{ width: '100%' }}
                                                    />
                                                </Form.Item>
                                            </Col>
                                        </Row>

                                    </>
                                );
                            }
                            else if (currentType === 'WEEKLY') {

                                return (
                                    <>
                                        <Divider />
                                        <Form.Item
                                            name={[props.name, 'schedule', 'interval']}
                                            label="Недельный интервал"
                                            rules={[{ required: true, message: '' }]}
                                            style={{ marginTop: '16px' }}
                                        >
                                            <InputNumber
                                                min={0}
                                                max={52}
                                            />
                                        </Form.Item>

                                        <Divider />
                                        <div>
                                            <Title level={5} style={{ marginTop: '0' }}>
                                                Дни недели:
                                            </Title>
                                            <Row gutter={[16, 16]}>
                                                {
                                                    weeks.map((day, index) => {
                                                        return (
                                                            <Col key={day} xs={24} md={6}>
                                                                <Form.Item
                                                                    name={[props.name, 'schedule', 'sendOutTimes', day]}
                                                                    label={weeksRu[index]}
                                                                    style={{ marginBottom: '4px' }}
                                                                >
                                                                    <TimePicker
                                                                        style={{ width: '100%' }}
                                                                        format="HH:mm"
                                                                    />

                                                                </Form.Item>
                                                            </Col>
                                                        );
                                                    })
                                                }
                                            </Row>
                                        </div>
                                    </>
                                );
                            }


                        }}
                    </Form.Item>

                    <Divider />

                    <Form.List
                        name={[props.name, 'questions']}
                    >

                        {(fields, { add, remove }) => (
                            <>
                                <Title level={5}>
                                    Вопросы
                                    <Button
                                        type="primary"
                                        onClick={() => add()}
                                        icon={<PlusOutlined />}
                                        style={{ marginLeft: '16px' }}
                                    />
                                </Title>
                                {fields.map(({ key, name, ...restField }) => (
                                    <Row
                                        key={key}
                                        gutter={16}
                                        align="stretch"
                                        wrap={false}
                                    >
                                        <Col flex={1}>
                                            <Form.Item
                                                {...restField}
                                                name={[name, 'text']}
                                                label="Вопрос"
                                                rules={[
                                                    { required: true, whitespace: true, message: '' },
                                                    { message: 'Вопрос должен содержать как минимум 2 символа', min: 2 }
                                                ]}
                                            >
                                                <Input placeholder="Вопрос" />
                                            </Form.Item>
                                        </Col>

                                        <Col style={{ display: 'flex', alignItems: 'center' }}>
                                            <DeleteQuestionPopover name={name} remove={remove} />
                                        </Col>
                                    </Row>
                                ))}
                            </>
                        )}
                    </Form.List>
                </Col>
            </Row>
        </div>
    );
}