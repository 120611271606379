import { DataStore } from './DataStore';

enum DataStoreKey {
    accessToken = 'accessToken',
    refreshToken = 'refreshToken'
}

class WebStorageDataStore implements DataStore {

    private currentStorage: Storage;

    constructor() {
        this.currentStorage = sessionStorage.length ? sessionStorage : localStorage;
    }

    get accessToken(): string | null {
        return this.currentStorage.getItem(DataStoreKey.accessToken);
    }

    set accessToken(value: string | null) {
        if (value) {
            this.currentStorage.setItem(DataStoreKey.accessToken, value);
        } else {
            this.currentStorage.removeItem(DataStoreKey.accessToken);
        }
    }

    get refreshToken(): string | null {
        return this.currentStorage.getItem(DataStoreKey.refreshToken);
    }

    set refreshToken(value: string | null) {
        if (value) {
            this.currentStorage.setItem(DataStoreKey.refreshToken, value);
        } else {
            this.currentStorage.removeItem(DataStoreKey.refreshToken);
        }
    }

    setStorage(rememberProfile: boolean) {
        this.currentStorage = rememberProfile ? localStorage : sessionStorage;
    }
}

export const dataStore = new WebStorageDataStore();