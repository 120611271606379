import { AuthResponse } from '../../../domain/auth/models/AuthResponse';
import { LoginBody } from '../../../domain/auth/models/LoginBody';
import { AuthRepository } from '../../../domain/auth/repository/AuthRepository';
import { dataStore } from '../../dataStore/WebStorageDataStore';
import NewtorkService from '../NetworkService';

class AuthService extends NewtorkService implements AuthRepository {

    async login(loginBody: LoginBody): Promise<AuthResponse> {

        const authResponse = await this.request('/auth/login', 'POST', loginBody) as AuthResponse;


        dataStore.setStorage(loginBody.rememberMe);
        dataStore.accessToken = authResponse.accessToken;
        dataStore.refreshToken = authResponse.refreshToken;

        return Promise.resolve(authResponse);
    }
}

export const authService = new AuthService();