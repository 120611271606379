import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { usersService } from '../data/network/services/UsersService';
import { CreateUserBody } from '../domain/users/models/CreateUserBody';
import { User } from '../domain/users/models/User';

interface UsersSliceState {
    users?: User[];
}

const initialState: UsersSliceState = {
    users: undefined
};

export function getSortedUsersByFullName(users: User[]) {
    const usersCopy = [...users];
    
    return usersCopy.sort((a, b) => {
        const fullNameA = `${a.lastName}${a.firstName}${a.middleName}`;
        const fullNameB = `${b.lastName}${b.firstName}${b.middleName}`;
        
        return fullNameA < fullNameB ? -1 : 1;
    });
}

const usersSlice = createSlice({
    name: 'users',
    initialState,
    reducers: {

    },
    extraReducers: (builder) => {
        builder.addCase(getUsers.fulfilled, (state, action) => {
            state.users = getSortedUsersByFullName(action.payload);
        });
        builder.addCase(createNewUser.fulfilled, (state, action) => {
            state.users = getSortedUsersByFullName([...state.users || [], action.payload]);
        });
        builder.addCase(editUser.fulfilled, (state, action) => {
            const editedUser = action.meta.arg;
            state.users = getSortedUsersByFullName([...state.users!.filter(user => user.id !== editedUser.id), editedUser]);
        });
        builder.addCase(deleteUser.fulfilled, (state, action) => {
            const userId = action.meta.arg;
            state.users = getSortedUsersByFullName(state.users!.filter(user => user.id !== userId));
        });
    }
});

export const getUsers = createAsyncThunk(
    'users/getUsers',
    async function () {

        const users = await usersService.getUsers();
        
        return users;
    }
);

export const createNewUser = createAsyncThunk(
    'users/createNewUser',
    async function (createUserBody: CreateUserBody) {
        const newUser = await usersService.createNewUser(createUserBody);
        
        return newUser;
    }
);

export const editUser = createAsyncThunk(
    'users/editUser',
    async function (editedUser: User) {
        await usersService.editUser(editedUser);
    }
);

export const deleteUser = createAsyncThunk(
    'users/deleteUser',
    async function (userId: string) {
        await usersService.deleteUser(userId);
    }
);

export default usersSlice.reducer;