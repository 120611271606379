import React, {  useEffect, useState } from 'react';
import './statistic.scss';
import { Card, Col, ConfigProvider, DatePicker, Row, Table, Typography } from 'antd';
import { useAppSelector, useAppDispatch } from '../../hooks/redux-hooks';

import DefaultList from '../components/defaultList/DefaultList';
import { getAllSurveys } from '../../slices/SurveysSlice';
import { Survey } from '../../domain/surveys/models/Survey';
import { SurveyStats } from '../../domain/staistic/models/SurveyStats';
import { getStatisticBySurveyId } from '../../slices/StatisticSlice';
import type { ColumnsType } from 'antd/es/table';

import 'dayjs/locale/ru';
import locale from 'antd/es/locale/ru_RU';
import dayjs from 'dayjs';

const { Title } = Typography;

interface SelectedSurveyCardProps {
    survey: Survey;
    stat: SurveyStats;
    selectedDates: [string, string];
    setSelectedDates: React.Dispatch<React.SetStateAction<[string, string]>>;
}

const SelectedSurveyCard = ({survey, stat, selectedDates, setSelectedDates}: SelectedSurveyCardProps) => {
   
    const dataSource: SurveyStats['users'] = stat.users;
    const maxAnswersAmount = stat.sendOutAmount;

    const columns: ColumnsType<SurveyStats['users'][0]> = [
        {
            title: 'ФИО',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Ответов',
            dataIndex: 'answersAmount',
            key: 'answersAmount',
            render: ((_value, {answersAmount}) => {
                return (<>{answersAmount}/{maxAnswersAmount}</>);
            }),
            sorter: {
                compare: (a, b) => a.answersAmount - b.answersAmount,
            },
        },
        {
            title: 'Символов/Ответ',
            dataIndex: 'charsPerAnswer',
            key: 'charsPerAnswer',
            render: ((_value, {charsPerAnswer,answersAmount}) => {
                return (((charsPerAnswer/answersAmount) || 0).toFixed(2));
            }),
            sorter: {

                compare: (a, b) => {
                    const valA = (a.charsPerAnswer/a.answersAmount);
                    const valB = (b.charsPerAnswer/b.answersAmount);
                    
                    return  valA - valB;
                }
            },
        },
    ];
    
    return (
        <Col span={24} lg={16}>
            <Card>
                <Row align="middle" justify="space-between" gutter={[20,20]}>
                    <Col xs={24}>
                        <Title level={3}>
                            {survey.title}
                        </Title>
                    </Col>
                    <Col xs={24}>
                        <ConfigProvider locale={locale}>

                      
                            <DatePicker.RangePicker defaultValue={[dayjs(selectedDates[0]), dayjs(selectedDates[1])]} onChange={(_val, date) => {
                                setSelectedDates(date);
                            }}/>
                        </ConfigProvider>
                    </Col>

                    <Col xs={24}>
                        <Table dataSource={dataSource} columns={columns} pagination={false} className="stat-table"/>
                    </Col>
                </Row>
            </Card>
        </Col>
    );
};

const defaultDates: [string, string] = [dayjs().locale('ru').startOf('week').format('YYYY-MM-DD'), dayjs().locale('ru').endOf('week').format('YYYY-MM-DD')];

export const Statisctic = () => {

    const [selectedSurvey, setSelectedSurvey] = useState<Survey>();


    const [selectedDates, setSelectedDates] = useState<[string, string]>(defaultDates);
    const { stat } = useAppSelector(state => state.statisticReducer);
    const { surveys } = useAppSelector(state => state.surveysReducer);

    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(getAllSurveys());
    }, []);

    const getStat = (selectedSurvey: Survey | undefined, selectedDates: [string, string]) => {
        if(selectedSurvey && selectedDates[0] && selectedDates[1]){
            dispatch(getStatisticBySurveyId({surveyId: selectedSurvey?.id, from:selectedDates[0], to: selectedDates[1]}));
        }
    };
    
    useEffect(() => {
        getStat(selectedSurvey, selectedDates);
    }, [selectedSurvey, selectedDates]);

    
    return (
        <>
            <Title>
                Статистика
            </Title>

            <Row gutter={[16, 16]}>
                <Col span={24} lg={8}>
                    <DefaultList
                        items={surveys || []}
                      
                        searchFunction={(survey, filter) => {
                            return survey.filter(survey => {
                                const trimAndLowerCaseName = `${survey.title}`.trim().toLowerCase();
                                const trimAndLowerCaseFilter = filter.trim().toLowerCase();

                                return trimAndLowerCaseName.includes(trimAndLowerCaseFilter);
                            });
                        }}
                        renderListItem={(survey) => {
                            return (
                                <span
                                    style={{ padding: '16px' }}
                                    onClick={() => setSelectedSurvey(survey)}
                                >
                                    {`${survey.title}`}
                                </span>

                            );
                        }}
                    />
                </Col>

                {
                    selectedSurvey && stat && <SelectedSurveyCard survey={selectedSurvey} stat={stat} selectedDates={selectedDates} setSelectedDates={setSelectedDates}/>
                }
            </Row>
        </>);
};