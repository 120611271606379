import { EmptyRespone } from '../../../domain/common/EmptyRespone';
import { CreateGroupBody } from '../../../domain/groups/models/CreateGroupBody';
import { Group } from '../../../domain/groups/models/Group';
import { GroupsRepository } from '../../../domain/groups/repository/GroupsRepository';
import { AddUserBody } from '../../../domain/users/models/AddUserBody';
import NewtorkService from '../NetworkService';

class GroupsService extends NewtorkService implements GroupsRepository {

    createNewGroup(createGroupBody: CreateGroupBody): Promise<Group> {
        return this.request('/groups', 'POST', createGroupBody);
    }

    getAllGroups(): Promise<Group[]> {
        return this.request('/groups', 'GET');
    }

    deleteGroup(groupId: string): Promise<EmptyRespone> {
        return this.request(`/groups/${groupId}`, 'DELETE');
    }

    addUserInGroup(groupId: string, addUserBody: AddUserBody): Promise<EmptyRespone> {
        return this.request(`/groups/${groupId}/users`, 'POST', addUserBody);
    }

    removeUserFromGroup(groupId: string, userId: string): Promise<EmptyRespone> {
        return this.request(`/groups/${groupId}/users/${userId}`, 'DELETE');
    }
}

export const groupsService = new GroupsService();