import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Card, Col, Divider, Empty, Form, Input, List, Modal, Row, Space, Typography } from 'antd';
import { useEffect, useState } from 'react';
import { CreateRepositoryBody } from '../../../../domain/repositories/models/CreateRepositoryBody';
import { User } from '../../../../domain/users/models/User';
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux-hooks';
import { CreateNewProjectProps, CreateNewProjectPropsGroupProperty, createNewProject } from '../../../../slices/ProjectsSlice';
import EditableList from '../../../components/editableList/EditableList';
import Notification from '../../../components/notification/Notification';
import { NewGroupFormItems } from '../NewGroupFormItems';
import { NewRepositoryFormItems } from '../NewRepositoryFormItems';
import './new-project-form.scss';

const { Title, Text } = Typography;

export function NewProjectForm() {

    const { users } = useAppSelector(state => state.usersReducer);
    const dispatch = useAppDispatch();
    const [selectedUsers, setSelectedUsers] = useState<User[]>([]);

    function createNewProjectHandler(value: CreateNewProjectProps) {
        dispatch(createNewProject(value));
    }

    function addToSelectedUsers(user: User) {
        if (!selectedUsers.some(usr => usr.id === user.id)) {
            setSelectedUsers([...selectedUsers, user]);
        }
    }

    function removeFromSelectedUsers(user: User) {
        setSelectedUsers([...selectedUsers.filter(usr => usr.id !== user.id)]);
    }

    const [isRepositoryModalOpen, setIsRepositoryModalOpen] = useState<'CLOSED' | 'NEW_REPOSITORY' | number>('CLOSED');
    const [isGroupModalOpen, setIsGroupModalOpen] = useState<'CLOSED' | 'NEW_GROUP' | number>('CLOSED');

    const [repositoryFormRef] = Form.useForm<CreateRepositoryBody>();
    const [groupFormRef] = Form.useForm<CreateNewProjectPropsGroupProperty>();
    const [projectFormRef] = Form.useForm<CreateNewProjectProps>();

    useEffect(() => {
        if (typeof isRepositoryModalOpen === 'number') {
            repositoryFormRef.setFieldsValue(projectFormRef.getFieldValue(['repositories', isRepositoryModalOpen]));
        }
        // eslint-disable-next-line
    }, [isRepositoryModalOpen])

    useEffect(() => {
        if (typeof isGroupModalOpen === 'number') {
            groupFormRef.setFieldsValue(projectFormRef.getFieldValue(['groups', isGroupModalOpen]));
        }
        // eslint-disable-next-line
    }, [isGroupModalOpen])

    const listProps = {
        bordered: true,
        locale: {
            emptyText: <Empty description="Пусто" />
        }
    };

    const NewRepositoryModal = () => {

        return (
            <Modal
                title={isRepositoryModalOpen === 'NEW_REPOSITORY' ? 'Добавить репозиторий' : 'Редактирование репозитория'}
                open={isRepositoryModalOpen !== 'CLOSED'}
                mask={false}
                destroyOnClose
                footer={null}
                width="1000px"
                onOk={() => setIsRepositoryModalOpen('CLOSED')}
                onCancel={() => setIsRepositoryModalOpen('CLOSED')}
            >
                <Form
                    layout="vertical"
                    form={repositoryFormRef}
                    onFinish={(repository) => {
                        let { repositories } = projectFormRef.getFieldsValue();

                        if (repositories === undefined) {
                            repositories = [];
                        }

                        if (typeof isRepositoryModalOpen === 'number') {
                            repositories[isRepositoryModalOpen] = repository;
                            projectFormRef.setFieldsValue({ repositories: repositories });
                        }
                        else if (isRepositoryModalOpen === 'NEW_REPOSITORY') {
                            projectFormRef.setFieldsValue({ repositories: [...repositories, repository] });
                        }
                    }}
                >
                    <NewRepositoryFormItems />

                    <Form.Item
                        noStyle
                    >
                        <Button
                            type="primary"
                            htmlType="submit"
                            block
                        >
                            {isRepositoryModalOpen === 'NEW_REPOSITORY' ? 'Добавить репозиторий' : 'Сохранить изменения'}
                        </Button>
                    </Form.Item>
                </Form>

            </Modal>
        );
    };

    const NewGroupModal = () => {

        return (
            <Modal
                title={isGroupModalOpen === 'NEW_GROUP' ? 'Новая группа' : 'Редактирование группы'}
                open={isGroupModalOpen !== 'CLOSED'}
                mask={false}
                destroyOnClose
                footer={null}
                width="1000px"
                onOk={() => setIsGroupModalOpen('CLOSED')}
                onCancel={() => setIsGroupModalOpen('CLOSED')}
            >
                <Form
                    layout="vertical"
                    form={groupFormRef}
                    onFinish={(group) => {
                        let { groups } = projectFormRef.getFieldsValue() || [];

                        if (groups === undefined) {
                            groups = [];
                        }

                        if (typeof isGroupModalOpen === 'number') {
                            groups[isGroupModalOpen] = group;
                            projectFormRef.setFieldsValue({ groups: groups });
                        }
                        else if (isGroupModalOpen === 'NEW_GROUP') {
                            projectFormRef.setFieldsValue({ groups: [...groups, group] });
                        }
                    }}
                >
                    <NewGroupFormItems />

                    <Form.Item
                        noStyle
                    >
                        <Button
                            type="primary"
                            htmlType="submit"
                            block
                        >
                            {isGroupModalOpen === 'NEW_GROUP' ? 'Добавить группу' : 'Сохранить изменения'}
                        </Button>
                    </Form.Item>
                </Form>

            </Modal>
        );
    };

    return (
        <Card>
            <Title level={3}>
                Создать проект
            </Title>

            <Form
                layout="vertical"
                onFinishFailed={(error) => Notification.handle({ notificationBody: 'Не заполнены требуемые поля', notificationType: 'warning' })}
                form={projectFormRef}
                onFinish={(values) => createNewProjectHandler(values)}
            >

                <Form.Item
                    label="Название"
                    name="name"
                    rules={[
                        { message: 'Введите название для проекта', required: true, whitespace: true },
                        { message: 'Название проекта должно содержать как минимум 3 символа', min: 3 }
                    ]}
                >
                    <Input />
                </Form.Item>

                <Divider />

                <NewGroupModal />
                <Form.Item shouldUpdate={(prevValues: CreateNewProjectProps, curValues: CreateNewProjectProps) => {
                    return JSON.stringify(prevValues.groups) !== JSON.stringify(curValues.groups);
                }}>
                    {(form) => {
                        const { groups } = form.getFieldsValue();

                        return (
                            <Form.Item name="groups">
                                <NewRepositoryModal />
                                <Title level={4}>
                                    Группы:
                                    <Button
                                        type="primary"
                                        onClick={() => setIsGroupModalOpen('NEW_GROUP')}
                                        icon={<PlusOutlined />}
                                        style={{ marginLeft: '16px' }}
                                    />
                                </Title>

                                <Row className="project-card-row">
                                    <Col span={24}>
                                        <List
                                            {...listProps}
                                            dataSource={groups}
                                            renderItem={(group, index) => {
                                                return (
                                                    <List.Item key={index}>
                                                        <Row
                                                            align="middle"
                                                            wrap={false}
                                                            style={{ width: '100%' }}
                                                        >
                                                            <Col flex={1}>
                                                                <Text ellipsis style={{ width: '90%' }}>
                                                                    {group?.name}
                                                                </Text>

                                                            </Col>

                                                            <Col>
                                                                <Space>
                                                                    <Button
                                                                        onClick={() => setIsGroupModalOpen(index)}
                                                                        icon={<EditOutlined />}
                                                                    />
                                                                    <Button
                                                                        danger
                                                                        onClick={() => {
                                                                            form.setFieldsValue({
                                                                                groups: groups?.filter((group, groupIndex) => groupIndex !== index)
                                                                            });
                                                                        }}
                                                                        icon={<DeleteOutlined />}
                                                                    />
                                                                </Space>
                                                            </Col>
                                                        </Row>
                                                    </List.Item>
                                                );
                                            }}
                                        />
                                    </Col>
                                </Row>
                            </Form.Item>
                        );
                    }}

                </Form.Item>

                <Divider />

                <EditableList
                    className="editable-list-users"
                    title="Выбранные пользователи:"
                    selectedItems={selectedUsers}
                    allItems={users || []}

                    renderSelectedListItem={(item, index) => {
                        return <Form.Item
                            name={['users', index, 'userId']}
                            initialValue={item.id}
                            noStyle
                        >
                            {item.lastName} {item.firstName} {item.middleName}
                        </Form.Item>;
                    }}

                    renderAllListItem={(item) => {
                        return <>{item.lastName} {item.firstName} {item.middleName}</>;
                    }}

                    searchFunction={(users, filter) => {

                        return users.filter(user => {
                            const trimAndLowerCaseFullname = `${user.lastName} ${user.firstName} ${user.middleName}`.trim().toLowerCase();
                            const trimAndLowerCaseFilter = filter.trim().toLowerCase();

                            return trimAndLowerCaseFullname.includes(trimAndLowerCaseFilter);
                        });
                    }}

                    insertInArray={addToSelectedUsers}
                    removeFromArray={removeFromSelectedUsers}
                />

                <Divider />

                <Form.Item shouldUpdate={(prevValues: CreateNewProjectProps, curValues: CreateNewProjectProps) => {
                    return JSON.stringify(prevValues.repositories) !== JSON.stringify(curValues.repositories);
                }}>
                    {(form) => {
                        const { repositories } = form.getFieldsValue();

                        return (
                            <Form.Item name="repositories">
                                <NewRepositoryModal />
                                <Title level={4}>
                                    Репозитории
                                    <Button
                                        type="primary"
                                        onClick={() => setIsRepositoryModalOpen('NEW_REPOSITORY')}
                                        icon={<PlusOutlined />}
                                        style={{ marginLeft: '16px' }}
                                    />
                                </Title>

                                <Row className="project-card-row">
                                    <Col span={24}>
                                        <List
                                            {...listProps}
                                            dataSource={repositories}
                                            renderItem={(repository, index) => {
                                                return (
                                                    <List.Item key={index}>
                                                        <Row
                                                            align="middle"
                                                            wrap={false}
                                                            style={{ width: '100%' }}
                                                        >
                                                            <Col flex={1}>
                                                                <Text ellipsis style={{ width: '90%' }}>
                                                                    {repository.name} <br/> <br/>
																	URL: <a 
                                                                        href={repository.gitlabUrl}
                                                                        target="_blank"
                                                                        rel="noopener noreferrer"
                                                                    >
                                                                        {repository.gitlabUrl}
                                                                    </a>
                                                                </Text>
                                                            </Col>

                                                            <Col>
                                                                <Space>

                                                                    <Button
                                                                        onClick={() => setIsRepositoryModalOpen(index)}
                                                                        icon={<EditOutlined />}
                                                                    />
                                                                    <Button
                                                                        danger
                                                                        onClick={() => {
                                                                            form.setFieldsValue({
                                                                                repositories: repositories?.filter((rep, repI) => repI !== index)
                                                                            });
                                                                        }}
                                                                        icon={<DeleteOutlined />}
                                                                    />
                                                                </Space>
                                                            </Col>
                                                        </Row>
                                                    </List.Item>
                                                );
                                            }} />
                                    </Col>
                                </Row>
                            </Form.Item>
                        );
                    }}
                </Form.Item>

                <Divider />
                <Form.Item
                    noStyle
                >
                    <Button
                        type="primary"
                        htmlType="submit"
                        block
                    >
                        Создать новый проект
                    </Button>
                </Form.Item>
            </Form>
        </Card >
    );
}