import { Form, Input } from 'antd';

interface NewRepositoryFormProps {
    name?: (string | number)[];
}

export function NewRepositoryFormItems(props: NewRepositoryFormProps) {
    const { name } = props;

    return (
        <>
            <Form.Item
                label="Название репозитория"
                name={name ? [...name, 'name'] : 'name'}

                rules={[
                    { message: 'Введите название для репозитория', required: true, whitespace: true },
                    { message: 'Название репозитория должно содержать как минимум 3 символа', min: 3 }
                ]}
            >
                <Input />
            </Form.Item >

            <Form.Item
                label="Gitlab url"
                name={name ? [...name, 'gitlabUrl'] : 'gitlabUrl'}

                rules={[{ message: 'Введите ссылку', required: true, whitespace: true }]}
            >
                <Input />
            </Form.Item>
        </>
    );
}