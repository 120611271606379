import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { projectsService } from '../data/network/services/ProjectsService';
import { Project } from '../domain/projects/models/Project';
import { CreateQuestionBody } from '../domain/questions/models/CreateQuestionBody';
import { CreateRepositoryBody } from '../domain/repositories/models/CreateRepositoryBody';
import { CreateSurveyBody } from '../domain/surveys/models/CreateSurveyBody';
import { AddUserBody } from '../domain/users/models/AddUserBody';
import { User } from '../domain/users/models/User';
import { createNewGroup, deleteGroup, editUsersInGroup } from './GroupsSlice';
import { createNewRepository, deleteRepository } from './RepositoriesSlice';
import { getSortedUsersByFullName } from './UsersSlice';
import { getSortedArrayByName } from './common/getSortedArrayByName';
import { NotificationProps } from '../domain/common/NotificationProps';



interface ProjectsSliceState {
    projects: Project[];
}

const initialState: ProjectsSliceState = {
    projects: [],
};

function getProjectById(projects: Project[], projectId: string | null) {
    return projects.find(project => project.id === projectId);
}

export const projectsSlice = createSlice({
    name: 'projects',
    initialState,
    reducers: {

    },
    extraReducers: (builder) => {
        builder.addCase(getAllProjects.fulfilled, (state, action) => {
            state.projects = getSortedArrayByName(action.payload);
        });
        builder.addCase(createNewGroup.fulfilled, (state, action) => {
            const currentProject = getProjectById(state.projects, action.meta.arg.createGroupBody.projectId);

            if (currentProject) {
                const newGroups = [...currentProject.groups, action.payload.newGroup];
                currentProject.groups = getSortedArrayByName(newGroups);
            }
        });
        builder.addCase(deleteGroup.fulfilled, (state, action) => {

            const { projectId, groupId } = action.meta.arg;

            const currentProject = getProjectById(state.projects, projectId);

            if (currentProject) {
                const newGroups = currentProject.groups.filter(group => group.id !== groupId);
                currentProject.groups = getSortedArrayByName(newGroups);
            }
        });
        builder.addCase(editUsersInGroup.fulfilled, (state, action) => {

            const { projectId, groupId } = action.meta.arg;

            const currentProject = getProjectById(state.projects, projectId);

            if (currentProject) {
                const currentGroup = currentProject.groups.find(group => group.id === groupId);

                if (currentGroup) {

                    currentGroup.users = getSortedUsersByFullName(action.payload.users);
                }
            }
        });
        builder.addCase(editUsersInProject.fulfilled, (state, action) => {

            const { projectId } = action.meta.arg;

            const currentProject = getProjectById(state.projects, projectId);

            if (currentProject) {
                currentProject.users = getSortedUsersByFullName(action.payload.users);
            }
        });
        builder.addCase(createNewRepository.fulfilled, (state, action) => {
            const { projectId } = action.meta.arg;
            const currentProject = getProjectById(state.projects, projectId);

            if (currentProject) {
                currentProject.repositories = getSortedArrayByName([...currentProject.repositories, action.payload.newRepository]);
            }
        });
        builder.addCase(deleteRepository.fulfilled, (state, action) => {
            const { projectId, repositoryId } = action.meta.arg;
            const currentProject = getProjectById(state.projects, projectId);

            if (currentProject) {
                currentProject.repositories = getSortedArrayByName(currentProject.repositories.filter(repository => repository.id !== repositoryId));
            }
        });
        builder.addCase(deleteProject.fulfilled, (state, action) => {
            const projectId = action.meta.arg;
            state.projects = state.projects.filter(project => project.id !== projectId);
        });
    }
});

export interface CreateNewProjectPropsGroupProperty {
    name: string;
    selectedUsers: AddUserBody[];
    surveys?: Omit<CreateSurveyBody & { questions?: CreateQuestionBody[], }, 'groupId'>[];
}

export interface CreateNewProjectProps {
    name: string;
    groups?: CreateNewProjectPropsGroupProperty[];
    users?: AddUserBody[];
    repositories?: CreateRepositoryBody[];
}

export const createNewProject = createAsyncThunk(
    'projects/createNewProject',
    async function (createProjectBody: CreateNewProjectProps, thunkApi) {

        let { name, repositories, users, groups } = createProjectBody;

        users = users || [];

        const newProject = await projectsService.createNewProject({ name: name });

        if (repositories && repositories.length) {
            repositories.forEach(createRepositoryBody => {
                thunkApi.dispatch(createNewRepository({ projectId: newProject.id, createRepositoryBody }));
            });
        }

        await thunkApi.dispatch(addUserInProject(
            {
                projectId: newProject.id,
                addUserBodies: users
            }
        ));

        if (groups && groups.length) {
            groups.forEach(group => {
                thunkApi.dispatch(createNewGroup({
                    createGroupBody: {
                        name: group.name,
                        projectId: newProject.id
                    },
                    selectedUsers: group.selectedUsers,
                    newSurveys: group.surveys
                }));
            });

        }


        await thunkApi.dispatch(getAllProjects());
    }
);

export const getAllProjects = createAsyncThunk(
    'projects/getAllProjects',
    async function () {
        const projects = await projectsService.getAllProjects();

        return projects;
    }
);

interface EditUsersInProjectParams {
    projectId: string;
    oldUsers: User[];
    newUsers: User[];
}

export const editUsersInProject = createAsyncThunk(
    'projects/editUsersInProject',
    async function ({ projectId, oldUsers, newUsers }: EditUsersInProjectParams, thunkApi) {

        const oldUsersArray = oldUsers.filter(x => newUsers.find(y => y.id === x.id));
        const removeUsersArray = oldUsers.filter(x => !newUsers.find(y => y.id === x.id));
        const newUsersArray = newUsers.filter(x => !oldUsersArray.find(y => y.id === x.id) && !removeUsersArray.find(y => y.id === x.id));

        let notification: NotificationProps = {
            notificationBody: 'Список пользователей успешно отредактирован',
            notificationType: 'success'
        };

        const promiseSettledResultRemoveUsers = await thunkApi.dispatch(
            removeUserFromProject({ projectId, users: removeUsersArray })
        ).unwrap();

        promiseSettledResultRemoveUsers.forEach((res, index) => {
            if (res.status === 'rejected') {
                oldUsersArray.push(removeUsersArray[index]);
            }
        });

        const promiseSettledResultAddUsers = await thunkApi.dispatch(addUserInProject(
            {
                projectId,
                addUserBodies: newUsersArray.map(user => {
                    return { userId: user.id };
                })
            }
        )).unwrap();

        promiseSettledResultAddUsers.forEach((res, index) => {
            if (res.status === 'rejected') {
                newUsersArray.splice(index, 1);
            }
        });

        const settledResults = [...promiseSettledResultRemoveUsers, ...promiseSettledResultAddUsers];
        const isResHasErrors = settledResults.find(res => res.status === 'rejected');
        const isResHasSuccess = settledResults.find(res => res.status === 'fulfilled');

        if (isResHasErrors || isResHasSuccess) {

            if (isResHasErrors && isResHasSuccess) {
                notification = {
                    notificationBody: 'Список пользователей отредактирован с ошибками',
                    notificationType: 'warning'
                };
            }
            else if (isResHasErrors && !isResHasSuccess) {
                notification = {
                    notificationBody: 'Список пользователей не удалось отредактировать',
                    notificationType: 'error'
                };
            }

            return { users: [...oldUsersArray, ...newUsersArray], notification };
        }

        return { users: [...oldUsersArray, ...newUsersArray] };
    }
);

export const addUserInProject = createAsyncThunk(
    'projects/addUserInProject',
    async function ({ projectId, addUserBodies }: { projectId: string, addUserBodies: AddUserBody[], }, thunkApi) {


        const promiseSettledResultAddUsers = await Promise.allSettled(
            addUserBodies.map(user => {
                return projectsService.addUserInProject(projectId, user);
            })
        );

        return promiseSettledResultAddUsers;
    }
);

export const removeUserFromProject = createAsyncThunk(
    'projects/removeUserFromProject',
    async function ({ projectId, users }: { projectId: string, users: User[], }) {

        const promiseSettledResultRemoveUsers = await Promise.allSettled(
            users.map(user => {
                return projectsService.removeUserFromProject(projectId, user.id);
            })
        );

        return promiseSettledResultRemoveUsers;
    }
);

export const deleteProject = createAsyncThunk(
    'projects/deleteProject',
    async function (projectId: string) {
        await projectsService.deleteProject(projectId);
    }
);

export default projectsSlice.reducer;