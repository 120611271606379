import { EmptyRespone } from '../../../domain/common/EmptyRespone';
import { CreateRepositoryBody } from '../../../domain/repositories/models/CreateRepositoryBody';
import { Repository } from '../../../domain/repositories/models/Repository';
import { RepositoriesRepository } from '../../../domain/repositories/repository/RepositoriesRepository';
import NewtorkService from '../NetworkService';

class RepositoriesService extends NewtorkService implements RepositoriesRepository {

    createNewRepository(projectId: string, createRepositoryBody: CreateRepositoryBody): Promise<Repository> {
        return this.request(`/projects/${projectId}/repositories`, 'POST', createRepositoryBody);
    }

    deleteRepository(projectId: string, repositoryId: string): Promise<EmptyRespone> {
        return this.request(`/projects/${projectId}/repositories/${repositoryId}`, 'DELETE');
    }
}

export const repositoriesService = new RepositoriesService();