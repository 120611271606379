import { Row, Col, Button, Typography } from 'antd';
import { useEffect, useState } from 'react';
import { Project } from '../../domain/projects/models/Project';
import { useAppSelector, useAppDispatch } from '../../hooks/redux-hooks';
import { getAllProjects } from '../../slices/ProjectsSlice';
import DefaultList from '../components/defaultList/DefaultList';
import { SelectedProjectCard } from './components/SelectedProjectCard';
import { NewProjectForm } from './components/newProjectForm/NewProjectForm';
import './projects.scss';
import { getUsers } from '../../slices/UsersSlice';

const { Title } = Typography;

export default function Projects() {
    const { projects } = useAppSelector(state => state.projectsReducer);

    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(getAllProjects());
        dispatch(getUsers());
    }, []);

    useEffect(() => {
        const seletedProject = projects.find(project => project.id === selectedProject?.id);
        showFormOrSelectedProject(seletedProject);
    }, [projects]);

    const [showNewProjectForm, setShowNewProjectForm] = useState<boolean>(false);
    const [selectedProject, setSelectedProject] = useState<Project>();

    function showFormOrSelectedProject(project: Project | undefined = undefined) {
        if (project) {
            setShowNewProjectForm(false);
            setSelectedProject(project);
        }
        else {
            setShowNewProjectForm(true);
            setSelectedProject(undefined);
        }
    }

    return (
        <>
            <Title>
                Проекты
            </Title>

            <Row gutter={[16, 16]}>
                <Col span={24} lg={8}>
                    <DefaultList
                        items={projects}
                        titleButton={
                            <Button onClick={() => showFormOrSelectedProject()}>
                                Создать
                            </Button>
                        }

                        searchFunction={(projects, filter) => {

                            return projects.filter(project => {
                                const trimAndLowerCaseName = project.name.trim().toLowerCase();
                                const trimAndLowerCaseFilter = filter.trim().toLowerCase();

                                return trimAndLowerCaseName.includes(trimAndLowerCaseFilter);
                            });
                        }}
                        renderListItem={(project) => {
                            return (
                                <span
                                    style={{ padding: '16px' }}
                                    onClick={() => showFormOrSelectedProject(project)}
                                >
                                    {project.name}
                                </span>

                            );
                        }}
                    />
                </Col>

                <Col span={24} lg={16}>
                    {
                        showNewProjectForm && <NewProjectForm />
                    }
                    {
                        selectedProject && <SelectedProjectCard selectedProject={selectedProject} />
                    }
                </Col>
            </Row>
        </>
    );
}
