import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useAppSelector, useAppDispatch } from '../../hooks/redux-hooks';
import { launchSurvey } from '../../slices/SurveysSlice';
import { Button, Card, Col, Form, Input, Row, Typography } from 'antd';
import { postSurveyAnswersBody } from '../../slices/SurveysSlice';
import { PostSurveyAnswersBody } from '../../domain/surveys/models/PostSurveyAnswersBody';
import './survey.scss';
import { CheckCircleOutlined } from '@ant-design/icons';

const { Title } = Typography;

const survey_form_saved_values_key = 'survey_form_saved_values';
const localStorageSavedValues = localStorage.getItem(survey_form_saved_values_key);
const savedValues: string[] = localStorageSavedValues ? JSON.parse(localStorageSavedValues) : [];

export default function Survey() {

    const [searchParams,] = useSearchParams();
    const token = searchParams.get('token');

    const { surveyLaunch } = useAppSelector(state => state.surveysReducer);
    const dispatch = useAppDispatch();

    const [isPostAnswerSuccessful, setIsPostAnswerSuccessful] = useState<boolean>(false);
    useEffect(() => {
        if (token) {
            dispatch(launchSurvey({ token }));
        }
    }, []);

    function postAnswersHandler(answersBody: PostSurveyAnswersBody) {
        dispatch(postSurveyAnswersBody(answersBody))
            .unwrap()
            .then(() => {
                localStorage.removeItem(survey_form_saved_values_key);
                window.close();
                setIsPostAnswerSuccessful(true);
            });
    }

    if (!surveyLaunch) {
        return (<div></div>);
    }

    if (isPostAnswerSuccessful) {
        return (
            <div className="is-post-answer-successful-message-wrapper">

                <div className="successful-message">
                    <Title className="icon">
                        <CheckCircleOutlined />
                    </Title>

                    <Title>
                        Ответы на опросник отправлены
                    </Title>
                </div>
            </div>
        );
    }
    
    return (
        <Row>
            <Col xs={24} lg={8}>
                <Title>
                    {surveyLaunch.title}
                </Title>
                <Card>
                    <Form
                        layout="vertical"
                        onFinish={(value) => postAnswersHandler(value)}  
                    >
                        <Form.Item
                            name={'token'}
                            initialValue={token}
                            noStyle
                        />
                        <Form.Item
                            name="answers"
                            noStyle
                        />
                        {
                            surveyLaunch.questions.map((question, index) => {
                                return (
                                    <div key={index}>
                                        <Form.Item
                                            name={['answers', index, 'questionId']}
                                            initialValue={question.id}
                                            noStyle
                                        />

                                        <Form.Item
                                            name={['answers', index, 'text']}
                                            initialValue={savedValues[index]}
                                            label={question.text}
                                        >
                                            <Input.TextArea onChange={(e) => {
                                                savedValues[index]= e.currentTarget.value;
                                                localStorage.setItem(survey_form_saved_values_key, JSON.stringify(savedValues));
                                            }}/> 
                                        </Form.Item>
                                    </div>
                                );
                            })
                        }

                        <Form.Item
                            noStyle
                        >
                            <Button
                                type="primary"
                                htmlType="submit"
                                block
                            >
                                Отправить ответ
                            </Button>
                        </Form.Item>
                    </Form>
                </Card>

            </Col>
        </Row>
    );
}
