import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { repositoriesService } from '../data/network/services/RepositoriesService';
import { CreateRepositoryBody } from '../domain/repositories/models/CreateRepositoryBody';
import { Repository } from '../domain/repositories/models/Repository';



interface RepositoriesSliceState {
    repositories: Repository[];
}

const initialState: RepositoriesSliceState = {
    repositories: []
};

export const repositoriesSlice = createSlice({
    name: 'repositories',
    initialState,
    reducers: {

    },
    extraReducers: (builder) => {

    }
});

export const createNewRepository = createAsyncThunk(
    'repositories/createNewRepository',
    async function ({ projectId, createRepositoryBody }: { projectId: string, createRepositoryBody: CreateRepositoryBody, }) {
        const newRepository = await repositoriesService.createNewRepository(projectId, createRepositoryBody);

        return { newRepository };
    }
);

export const deleteRepository = createAsyncThunk(
    'repositories/deleteRepository',
    async function ({ projectId, repositoryId }: { projectId: string, repositoryId: string, }) {
        await repositoriesService.deleteRepository(projectId, repositoryId);
    }
);

export default repositoriesSlice.reducer;