import { SurveyStats } from '../../../domain/staistic/models/SurveyStats';
import NewtorkService from '../NetworkService';

class StatisticService extends NewtorkService {

    getStatBySurveyId(surveyId: string, from: string, to: string): Promise<SurveyStats> {
        return this.request(`/surveys/${surveyId}/stats`, 'GET', {from, to});
    }

}

export const statisticService = new StatisticService();