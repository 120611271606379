import { EmptyRespone } from '../../../domain/common/EmptyRespone';
import { CreateQuestionBody } from '../../../domain/questions/models/CreateQuestionBody';
import { Question } from '../../../domain/questions/models/Question';
import { UpdateQuestionBody } from '../../../domain/questions/models/UpdateQuestionBody';
import { QuestionsRepository } from '../../../domain/questions/repository/QuestionsRepository';

import NewtorkService from '../NetworkService';

class QuestionsService extends NewtorkService implements QuestionsRepository {

    createNewQuestion(surveyId: string, createQuestionBody: CreateQuestionBody): Promise<Question> {
        return this.request(`/surveys/${surveyId}/questions`, 'POST', createQuestionBody);
    }

    editQuestion(surveyId: string, questionId: string, updateQuestionBody: UpdateQuestionBody): Promise<Question> {
        return this.request(`/surveys/${surveyId}/questions/${questionId}`, 'PUT', updateQuestionBody);
    }

    deleteQuestion(surveyId: string, questionId: string): Promise<EmptyRespone> {
        return this.request(`/surveys/${surveyId}/questions/${questionId}`, 'DELETE');
    }
}

export const questionsService = new QuestionsService();