import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { questionsService } from '../data/network/services/QuestionsService';
import { CreateQuestionBody } from '../domain/questions/models/CreateQuestionBody';
import { UpdateQuestionBody } from '../domain/questions/models/UpdateQuestionBody';
import { Question } from '../domain/questions/models/Question';


interface QuestionsSliceState {

}

const initialState: QuestionsSliceState = {

};

export const questionsSlice = createSlice({
    name: 'questions',
    initialState,
    reducers: {

    },
    extraReducers: (builder) => {

    }
});


interface EditQuestionInSurveyParams {
    surveyId: string;
    oldQuestions: Question[];
    newQuestions: Question[];
}

export const editQuestonsInSurvey = createAsyncThunk(
    'questions/editQuestonsInSurvey',
    async function ({ surveyId, oldQuestions, newQuestions }: EditQuestionInSurveyParams, thunkApi) {


        const oldQuestionsArray = oldQuestions.filter(oldQuestion =>
            newQuestions.find(newQuestion => {
                return oldQuestion.id === newQuestion.id;
            }));

        const removeQuestionsArray = oldQuestions.filter(oldQuestion => !newQuestions.find(newQuestion => {
            return oldQuestion.id === newQuestion.id;
        }));


        let newQuestionsArray = newQuestions.filter(newQuestion =>
            !oldQuestionsArray.find(oldQuestion => {
                return newQuestion.id === oldQuestion.id;
            }) &&
            !removeQuestionsArray.find(removeQuestion => {
                return newQuestion.id === removeQuestion.id;
            }));

        const editedQuestionsArray = newQuestions.filter(newQuestion => {
            const prev = oldQuestionsArray.find(oldQuestion => {
                return oldQuestion.id === newQuestion.id;
            });

            if (prev) {

                return JSON.stringify(prev) !== JSON.stringify(newQuestion);
            }

            return false;
        });

        if (newQuestionsArray.length) {
            newQuestionsArray.forEach(newQuestion => {
                thunkApi.dispatch(createNewQuestion({ surveyId, createQuestionBody: newQuestion }));
            });
        }

        if (removeQuestionsArray.length) {
            removeQuestionsArray.forEach((removeQuestion) => {
                if ('id' in removeQuestion) {
                    thunkApi.dispatch(deleteQuestion({ surveyId, questionId: removeQuestion.id }));
                }
            });
        }

        if (editedQuestionsArray.length) {
            editedQuestionsArray.forEach(editedQuestion => {
                if ('id' in editedQuestion) {
                    thunkApi.dispatch(editQuestion({ surveyId, questionId: editedQuestion.id, updateQuestionBody: editedQuestion }));
                }
            });
        }

    }
);

export const createNewQuestion = createAsyncThunk(
    'questions/createNewQuestion',
    async function ({ surveyId, createQuestionBody }: { surveyId: string, createQuestionBody: CreateQuestionBody, }) {
        const newQuestion = await questionsService.createNewQuestion(surveyId, createQuestionBody);

        return { newQuestion };
    }
);

interface EditQuestionParams {
    surveyId: string;
    questionId: string;
    updateQuestionBody: UpdateQuestionBody;
}

export const editQuestion = createAsyncThunk(
    'questions/editQuestion',
    async function ({ surveyId, questionId, updateQuestionBody }: EditQuestionParams) {
        const editedQuestion = await questionsService.editQuestion(surveyId, questionId, updateQuestionBody);

        return { editedQuestion };
    }
);

export const deleteQuestion = createAsyncThunk(
    'questions/deleteQuestion',
    async function ({ surveyId, questionId }: { surveyId: string, questionId: string, }) {
        await questionsService.deleteQuestion(surveyId, questionId);
    }
);

export default questionsSlice.reducer;