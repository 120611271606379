import { notification } from 'antd';
import { NotificationProps } from '../../../domain/common/NotificationProps';

export default class Notification {
    static handle({ notificationBody, notificationType }: NotificationProps) {

        notification.open(
            {
                message:
                    <div style={{ whiteSpace: 'pre-line' }}>
                        {typeof notificationBody === 'string' ? notificationBody : notificationBody.message}
                    </div>,
                type: notificationType
            }
        );
    }
}