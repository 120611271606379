import { Button, Card, Col, Divider, Form, Input, InputNumber, Modal, Popover, Row, Space, Typography } from 'antd';
import { useEffect, useState } from 'react';
import DefaultList from '../components/defaultList/DefaultList';
import { CreateUserBody } from '../../domain/users/models/CreateUserBody';
import { useAppDispatch, useAppSelector } from '../../hooks/redux-hooks';
import { createNewUser, deleteUser, editUser, getUsers } from '../../slices/UsersSlice';
import './users.scss';
import { User } from '../../domain/users/models/User';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';

const { Title, Text } = Typography;

export default function Users() {

    const { users } = useAppSelector(state => state.usersReducer);
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(getUsers());
    }, []);

    const [showNewUserForm, setShowNewUserForm] = useState<boolean>(false);
    const [selectedUser, setSelectedUser] = useState<User>();

    function createNewUserHandler(createUserBody: CreateUserBody) {
        dispatch(createNewUser(createUserBody));
    }

    function editUserHandler(editedUser: User) {
        dispatch(editUser(editedUser)).unwrap().then(() => {
            setSelectedUser(editedUser);
        });
    }

    function showFormOrSelectedUser(user: User | undefined = undefined) {
        if (user) {
            setShowNewUserForm(false);
            setSelectedUser(user);
        }
        else {
            setShowNewUserForm(true);
            setSelectedUser(undefined);
        }
    }

    const UserForm = (props: { user?: User, }) => {

        let titleAndButtonText = 'Добавить пользователя';
        let formFinish = createNewUserHandler;

        if (props.user !== undefined) {
            titleAndButtonText = 'Редактировать пользователя';
            formFinish = (createUserBody) => {
                editUserHandler({ ...createUserBody, id: props.user!.id });
            };
        }

        return (
            <>
                <Title level={3}>
                    {titleAndButtonText}
                </Title>

                <Form
                    layout="vertical"
                    onFinish={formFinish}
                    initialValues={props.user}
                >

                    <Form.Item
                        label="Фамилия"
                        name="lastName"
                        rules={[
                            { message: '', required: true, whitespace: true },
                            { message: 'Фамилия должна содержать как минимум 2 символа', min: 2 }
                        ]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="Имя"
                        name="firstName"
                        rules={[
                            { message: '', required: true, whitespace: true },
                            { message: 'Имя должно содержать как минимум 2 символа', min: 2 }
                        ]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="Отчество"
                        name="middleName"
                        rules={[
                            { message: '', required: true, whitespace: true },
                            { message: 'Отчество должно содержать как минимум 2 символа', min: 2 }
                        ]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="Discord Id"
                        name="discordId"
                        rules={[{ message: '', required: true }]}
                    >
                        <InputNumber min={0} style={{ width: '100%' }} stringMode />
                    </Form.Item>

                    <Form.Item
                        label="Gitlab Id"
                        name="gitlabId"
                        rules={[{ message: '', required: true }]}
                    >
                        <InputNumber min={0} style={{ width: '100%' }} stringMode />
                    </Form.Item>

                    <Divider />

                    <Form.Item
                        noStyle
                    >
                        <Button
                            type="primary"
                            htmlType="submit"
                            block
                        >
                            {titleAndButtonText}
                        </Button>
                    </Form.Item>
                </Form>
            </>

        );
    };

    const SelectedUserCard = (props: { selectedUser: User, }) => {
        const { lastName, firstName, middleName, discordId, gitlabId } = props.selectedUser;

        const [isOpen, setIsOpen] = useState(false);
        const [isEditUserModalOpen, setisEditUserModalOpen] = useState(false);

        function deleteUserHandler() {
            dispatch(deleteUser(props.selectedUser.id))
                .unwrap()
                .then(() => {
                    setSelectedUser(undefined);
                });
        }

        return (
            <Col span={24} lg={16}>

                <Modal
                    open={isEditUserModalOpen}
                    mask={false}
                    destroyOnClose
                    footer={null}
                    onOk={() => setisEditUserModalOpen(false)}
                    onCancel={() => setisEditUserModalOpen(false)}
                >
                    <UserForm user={props.selectedUser} />
                </Modal>
                <Card>
                    <Row align="middle" justify="space-between">
                        <Col>
                            <Title level={3}>
                                {lastName} {firstName} {middleName}
                            </Title>
                        </Col>

                        <Col>
                            <Space>
                                <Button
                                    icon={<EditOutlined />}
                                    title="Редактировать пользователя"
                                    onClick={() => { setisEditUserModalOpen(true); }}
                                />

                                <Popover
                                    placement="left"
                                    trigger="click"
                                    title="Удалить пользователя?"
                                    open={isOpen}
                                    onOpenChange={setIsOpen}

                                    content={
                                        <>
                                            <Button
                                                danger
                                                onClick={() => {
                                                    setIsOpen(false);
                                                    deleteUserHandler();
                                                }}
                                            >
                                                Да
                                            </Button>
                                            <Button
                                                style={{ marginLeft: '16px' }}
                                                onClick={() => setIsOpen(false)}
                                            >
                                                Нет
                                            </Button>
                                        </>
                                    }
                                >
                                    <Button
                                        danger
                                        icon={<DeleteOutlined />}
                                        title="Удалить пользователя"
                                    />

                                </Popover>
                            </Space>
                        </Col>
                    </Row>


                    <Row>
                        <Text>
                            discord ID: {discordId}
                        </Text>
                    </Row>

                    <Text>
                        gitlab ID: {gitlabId || 'null'}
                    </Text>
                </Card>
            </Col>
        );
    };

    return (
        <>
            <Title>
                Пользователи
            </Title>

            <Row gutter={[16, 16]}>
                <Col span={24} lg={8}>
                    <DefaultList
                        items={users || []}
                        titleButton={
                            <Button onClick={() => showFormOrSelectedUser()}>
                                Добавить
                            </Button>
                        }
                        searchFunction={(users, filter) => {
                            return users.filter(user => {
                                const trimAndLowerCaseFullname = `${user.lastName} ${user.firstName} ${user.middleName}`.trim().toLowerCase();
                                const trimAndLowerCaseFilter = filter.trim().toLowerCase();

                                return trimAndLowerCaseFullname.includes(trimAndLowerCaseFilter);
                            });
                        }}
                        renderListItem={(user) => {
                            return (
                                <span
                                    style={{ padding: '16px' }}
                                    onClick={() => showFormOrSelectedUser(user)}
                                >
                                    {`${user.lastName} ${user.firstName} ${user.middleName}`}
                                </span>

                            );
                        }}
                    />
                </Col>

                {
                    showNewUserForm &&
                    <Col span={24} lg={16}>
                        <Card>
                            <UserForm />
                        </Card>
                    </Col>
                }
                {
                    selectedUser && <SelectedUserCard selectedUser={selectedUser} />
                }
            </Row>
        </>
    );
}