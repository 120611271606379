import { Card, Empty, Input, List } from 'antd';
import React, { useState } from 'react';
import './default-list.scss';


interface GroupsListProps<T> {
    items: Array<T>;
    titleButton?: JSX.Element;
    renderListItem: (item: T) => React.ReactNode;
    searchFunction: (items: Array<T>, filter: string) => Array<T>;
}

export default function DefaultList<T>(props: GroupsListProps<T>) {

    const [filter, setFilter] = useState('');

    function GroupItem(group: T) {

        return (
            <List.Item
                className="default-list-item"
            >
                {props.renderListItem(group)}
            </List.Item>
        );
    }

    return (
        <Card
            title={
                <div className="card-default-list-title">
                    <Input
                        placeholder="Поиск"
                        onChange={(changeEvent) => setFilter(changeEvent.currentTarget.value)}

                    />

                    {props.titleButton}
                </div>
            }
        >

            <List
                className="default-list"
                dataSource={props.searchFunction(props.items, filter)}
                renderItem={(item) => (
                    GroupItem(item)
                )}
                locale={{ emptyText: <Empty description="Пусто" /> }}
            />

        </Card>
    );
}