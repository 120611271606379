import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Card, Col, Divider, Empty, Form, List, Modal, Popover, Row, Space, Spin, Typography } from 'antd';
import { useState } from 'react';
import { CreateGroupBody } from '../../../domain/groups/models/CreateGroupBody';
import { Group } from '../../../domain/groups/models/Group';
import { Project } from '../../../domain/projects/models/Project';
import { CreateRepositoryBody } from '../../../domain/repositories/models/CreateRepositoryBody';
import { Survey } from '../../../domain/surveys/models/Survey';
import { AddUserBody } from '../../../domain/users/models/AddUserBody';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux-hooks';
import { createNewGroup, deleteGroup } from '../../../slices/GroupsSlice';
import { createNewRepository, deleteRepository } from '../../../slices/RepositoriesSlice';
import { EditGroupForm } from './EditGroupForm';
import { EditUsersListForm } from './EditUsersListForm';
import { NewGroupFormItems } from './NewGroupFormItems';
import { NewRepositoryFormItems } from './NewRepositoryFormItems';
import { deleteProject } from '../../../slices/ProjectsSlice';

const { Title, Text } = Typography;



export function SelectedProjectCard(props: { selectedProject: Project, }) {

    const { selectedProject } = props;
    const { isLoading } = useAppSelector(state => state.notificationReducer);

    const dispatch = useAppDispatch();
    const [isNewGroupModalOpen, setIsNewGroupModalOpen] = useState(false);
    const [isEditUsersListModalOpen, setIsEditUsersListModalOpen] = useState(false);
    const [isNewRepositoryListModalOpen, setIsNewRepositoryListModalOpen] = useState(false);
    const [selectedGroup, setSelectedGroup] = useState<Group>();

    function addNewRepositoryHandler(createRepositoryBody: CreateRepositoryBody) {
        dispatch(createNewRepository({ projectId: selectedProject.id, createRepositoryBody }));
    }

    function addNewGroupHandler(value: { name: string, surveys?: Survey[], selectedUsers: AddUserBody[], }) {
        const createGroupBody: CreateGroupBody = {
            name: value.name,
            projectId: selectedProject.id
        };

        dispatch(createNewGroup(
            {
                createGroupBody,
                selectedUsers: value.selectedUsers,
                newSurveys: value.surveys
            }
        )).then(() => {
            setIsNewGroupModalOpen(false);
        });

    }

    function deleteProjectHandler(projectId: string) {
        dispatch(deleteProject(projectId));
    }

    const listProps = {
        bordered: true,
        locale: {
            emptyText: <Empty description="Пусто" />
        }
    };

    const RepositoryDeleteButtonPopover = (props: { projectId: string, repositoryId: string, }) => {

        const [isOpen, setIsOpen] = useState(false);

        function deleteRepositoryHandler() {
            dispatch(deleteRepository(props));
        }

        return (
            <Popover
                placement="left"
                trigger="click"
                title="Удалить репозиторий?"
                open={isOpen}
                onOpenChange={setIsOpen}

                content={
                    <>
                        <Button
                            danger
                            onClick={() => {
                                setIsOpen(false);
                                deleteRepositoryHandler();
                            }}
                        >
                            Да
                        </Button>
                        <Button
                            style={{ marginLeft: '16px' }}
                            onClick={() => setIsOpen(false)}
                        >
                            Нет
                        </Button>
                    </>
                }
            >
                <Button
                    icon={<DeleteOutlined />}
                    danger
                    title="Удалить репозиторий"
                />
            </Popover>
        );
    };

    const GroupDeleteButtonPopover = (props: { projectId: string, groupId: string, }) => {

        const [isOpen, setIsOpen] = useState(false);

        function deleteGroupHandler() {
            dispatch(deleteGroup(props));
        }

        return (
            <Popover
                placement="left"
                trigger="click"
                title="Удалить группу?"
                open={isOpen}
                onOpenChange={setIsOpen}

                content={
                    <>
                        <Button
                            danger
                            onClick={() => {
                                setIsOpen(false);
                                deleteGroupHandler();
                            }}
                        >
                            Да
                        </Button>
                        <Button
                            style={{ marginLeft: '16px' }}
                            onClick={() => setIsOpen(false)}
                        >
                            Нет
                        </Button>
                    </>
                }
            >
                <Button
                    icon={<DeleteOutlined />}
                    danger
                    title="Удалить группу"
                />


            </Popover>
        );
    };

    const [isDeletePopoverOpen, setIsDeletePopoverOpen] = useState(false);

    return (
        <Card>
            <Row align="middle" justify="space-between">
                <Col>
                    <Title level={3}>
                        {selectedProject.name}
                    </Title>
                </Col>

                <Col>
                    <Popover
                        placement="left"
                        trigger="click"
                        title="Удалить проект?"
                        open={isDeletePopoverOpen}
                        onOpenChange={setIsDeletePopoverOpen}

                        content={
                            <>
                                <Button
                                    danger
                                    onClick={() => {
                                        setIsDeletePopoverOpen(false);
                                        deleteProjectHandler(selectedProject.id);
                                    }}
                                >
                                    Да
                                </Button>
                                <Button
                                    style={{ marginLeft: '16px' }}
                                    onClick={() => setIsDeletePopoverOpen(false)}
                                >
                                    Нет
                                </Button>
                            </>
                        }
                    >
                        <Button
                            danger
                            icon={<DeleteOutlined />}
                            title="Удалить проект"
                        />

                    </Popover>
                </Col>
            </Row>


            <Modal
                title="Новая группа"
                open={isNewGroupModalOpen}
                mask={false}
                destroyOnClose
                footer={null}
                width="1000px"
                onOk={() => setIsNewGroupModalOpen(false)}
                onCancel={() => setIsNewGroupModalOpen(false)}
            >
                <Spin spinning={isLoading}>
                    <Form
                        layout="vertical"
                        onFinish={(value) => {
                            addNewGroupHandler(value);
                        }}
                    >
                        <NewGroupFormItems />
                        <Form.Item
                            noStyle
                        >
                            <Button
                                type="primary"
                                htmlType="submit"
                                block
                            >
                                Добавить новую группу
                            </Button>
                        </Form.Item>
                    </Form>

                </Spin>

            </Modal>

            <Modal
                title={`Группа: ${selectedGroup?.name}`}
                open={selectedGroup !== undefined}
                mask={false}
                destroyOnClose
                footer={null}
                width="1000px"
                onOk={() => setSelectedGroup(undefined)}
                onCancel={() => setSelectedGroup(undefined)}
            >
                <Spin spinning={isLoading}>
                    <EditGroupForm
                        selectedProjectId={selectedProject.id}
                        selectedGroup={selectedGroup}
                        closeModal={() => setSelectedGroup(undefined)}
                    />
                </Spin>

            </Modal>

            <Row className="project-card-row">
                <Col span={24}>
                    <Title level={4}>
                        Группы:
                        <Button
                            type="primary"
                            style={{ marginLeft: '16px' }}
                            icon={<PlusOutlined />}
                            title="Добавить группу"
                            onClick={() => setIsNewGroupModalOpen(true)}
                        />
                    </Title>

                    <List
                        {...listProps}
                        dataSource={selectedProject.groups}
                        renderItem={(group) => {
                            return (
                                <List.Item>

                                    <Row align="middle" wrap={false} style={{ width: '100%' }}>
                                        <Col flex={1}>

                                            <Text ellipsis style={{ width: '90%' }}>
                                                {group.name} {group.isMain && <Text style={{ paddingLeft: '16px' }} type="secondary">Главная</Text>}
                                            </Text>
                                        </Col>

                                        <Col>
                                            <Space>
                                                <Button
                                                    icon={<EditOutlined />}
                                                    title="Редактировать группу"
                                                    onClick={() => { setSelectedGroup(group); }}
                                                />
                                                {
                                                    !group.isMain && <GroupDeleteButtonPopover projectId={selectedProject.id} groupId={group.id} />
                                                }

                                            </Space>

                                        </Col>
                                    </Row>
                                </List.Item>
                            );
                        }}
                    />
                </Col>
            </Row>

            <Divider />
            <Modal
                title="Список пользователей"
                open={isEditUsersListModalOpen}
                mask={false}
                destroyOnClose
                footer={null}
                width="1000px"
                onOk={() => setIsEditUsersListModalOpen(false)}
                onCancel={() => setIsEditUsersListModalOpen(false)}
            >
                <Spin spinning={isLoading}>
                    <EditUsersListForm selectedProject={selectedProject} />
                </Spin>
            </Modal>
            <Row className="project-card-row">
                <Col span={24}>
                    <Title level={4}>
                        Пользователи:

                        <Button
                            type="primary"
                            style={{ marginLeft: '16px' }}
                            icon={<EditOutlined />}
                            title="Редактировать список пользователей"
                            onClick={() => setIsEditUsersListModalOpen(true)}
                        />
                    </Title>

                    <List
                        {...listProps}
                        dataSource={selectedProject.users}
                        renderItem={(user) => {
                            return (
                                <List.Item>
                                    <Text ellipsis>
                                        {user.lastName} {user.firstName} {user.middleName}
                                    </Text>
                                </List.Item>
                            );
                        }}
                    />
                </Col>
            </Row>

            <Divider />

            <Modal
                title="Новый репозиторий"
                open={isNewRepositoryListModalOpen}
                mask={false}
                destroyOnClose
                footer={null}
                width="1000px"
                onOk={() => setIsNewRepositoryListModalOpen(false)}
                onCancel={() => setIsNewRepositoryListModalOpen(false)}
            >
                <Spin spinning={isLoading}>
                    <Form
                        layout="vertical"
                        onFinish={(value) => addNewRepositoryHandler(value)}
                    >
                        <NewRepositoryFormItems />
                        <Form.Item
                            noStyle
                        >
                            <Button
                                type="primary"
                                htmlType="submit"
                                block
                            >
                                Добавить репозиторий
                            </Button>
                        </Form.Item>
                    </Form>

                </Spin>

            </Modal>
            <Row className="project-card-row">
                <Col span={24}>
                    <Title level={4}>
                        Репозитории:

                        <Button
                            type="primary"
                            style={{ marginLeft: '16px' }}
                            icon={<PlusOutlined />}
                            title="Добавить новый репозиторий"
                            onClick={() => setIsNewRepositoryListModalOpen(true)}
                        />
                    </Title>

                    <List
                        {...listProps}
                        dataSource={selectedProject.repositories}
                        renderItem={(repository) => {
                            return (
                                <List.Item >
                                    <Row align="middle" wrap={false} style={{ width: '100%' }}>
                                        <Col flex={1}>
                                            <Text ellipsis style={{ width: '90%' }}>
                                                {repository.name} <br /> <br />
                                                URL: <a
                                                    href={repository.gitlabUrl}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    {repository.gitlabUrl}
                                                </a>
                                            </Text>
                                        </Col>

                                        <Col>
                                            <Space>
                                                <RepositoryDeleteButtonPopover
                                                    repositoryId={repository.id}
                                                    projectId={selectedProject.id}
                                                />
                                            </Space>
                                        </Col>
                                    </Row>
                                </List.Item>
                            );
                        }}
                    />
                </Col>
            </Row>
        </Card>
    );
}