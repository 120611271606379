import { EmptyRespone } from '../../../domain/common/EmptyRespone';
import { CreateSurveyBody } from '../../../domain/surveys/models/CreateSurveyBody';
import { LaunchSurveyBody } from '../../../domain/surveys/models/LaunchSurveyBody';
import { PostSurveyAnswersBody } from '../../../domain/surveys/models/PostSurveyAnswersBody';
import { Survey } from '../../../domain/surveys/models/Survey';
import { UpdateSurveyBody } from '../../../domain/surveys/models/UpdateSurveyBody';
import { SurveysRepository } from '../../../domain/surveys/repository/SurveysRepository';
import NewtorkService from '../NetworkService';

class SurveysService extends NewtorkService implements SurveysRepository {


    createNewSurvey(createSurveyBody: CreateSurveyBody): Promise<Survey> {
        return this.request('/surveys', 'POST', createSurveyBody);
    }

    getAllSurveys(): Promise<Survey[]> {
        return this.request('/surveys', 'GET');
    }

    editSurvey(surveyId: string, updateSurveyBody: UpdateSurveyBody): Promise<Survey> {
        return this.request(`/surveys/${surveyId}`, 'PUT', updateSurveyBody);

    }

    deleteSurvey(surveyId: string): Promise<EmptyRespone> {
        return this.request(`/surveys/${surveyId}`, 'DELETE');
    }

    launchSurvey(launchSurveyBody: LaunchSurveyBody): Promise<Survey> {
        return this.request('/surveys/launch', 'POST', launchSurveyBody);


    }

    postAnswers(postSurveyAnswersBody: PostSurveyAnswersBody): Promise<EmptyRespone> {
        return this.request('/surveys/answers', 'POST', postSurveyAnswersBody);
    }
}

export const surveysService = new SurveysService();