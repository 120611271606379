import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { statisticService } from '../data/network/services/StatisticService';
import { SurveyStats } from '../domain/staistic/models/SurveyStats';

interface StatisticSliceState {
    stat?: SurveyStats;
}

const initialState: StatisticSliceState = {
    stat: undefined
};


const statisticSlice = createSlice({
    name: 'statistic',
    initialState,
    reducers: {

    },
    extraReducers: (builder) => {
        builder.addCase(getStatisticBySurveyId.fulfilled, (state, action) => {
            state.stat = action.payload;
        });
    }
});

export const getStatisticBySurveyId = createAsyncThunk(
    'statistic/getStatisticBySurveyId',
    async function ({surveyId, from, to}:{surveyId: string, from: string, to: string,}) {
        return await statisticService.getStatBySurveyId(surveyId, from, to);
    }
);

export default statisticSlice.reducer;