import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { surveysService } from '../data/network/services/SurveysService';
import { CreateSurveyBody } from '../domain/surveys/models/CreateSurveyBody';
import { LaunchSurveyBody } from '../domain/surveys/models/LaunchSurveyBody';
import { PostSurveyAnswersBody } from '../domain/surveys/models/PostSurveyAnswersBody';
import { Survey } from '../domain/surveys/models/Survey';
import { CreateQuestionBody } from '../domain/questions/models/CreateQuestionBody';
import { editQuestonsInSurvey, createNewQuestion, deleteQuestion, editQuestion } from './QuestionsSlice';
import { questionsService } from '../data/network/services/QuestionsService';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { ScheduleWeekly } from '../domain/surveys/models/Schedule';

dayjs.extend(customParseFormat);

interface SurveysSliceState {
    surveys?: Survey[];
    surveyLaunch?: Survey;
}

const initialState: SurveysSliceState = {
    surveys: undefined,
    surveyLaunch: undefined
};

function formatSendOutTimeToDayJs(survey: Survey) {

    if (survey.schedule.type === 'DAILY') {
        survey.schedule.sendOutTime = dayjs(survey.schedule.sendOutTime, 'HH:mm:ss');

        return survey;

    }
    else {
        const outTimes = survey.schedule.sendOutTimes;

        for (let [key, value] of Object.entries(outTimes)) {

            if (typeof value === 'string') {
                outTimes[key as keyof ScheduleWeekly['sendOutTimes']] = dayjs(value, 'HH:mm:ss');
            }
        }

        return {
            ...survey,
            schedule: {
                ...survey.schedule,
                sendOutTimes: outTimes
            }
        };
    }
}

export function formatSendOutTimeToString(survey: Survey) {

    if (survey.schedule.type === 'DAILY' && typeof survey.schedule.sendOutTime !== 'string') {
        survey.schedule.sendOutTime = survey.schedule.sendOutTime.format('HH:mm');
    }
    else {
        if (survey.schedule.type === 'WEEKLY') {
            const outTimes = survey.schedule.sendOutTimes;
            for (let [key, value] of Object.entries(outTimes)) {

                if (typeof value !== 'string' && typeof value !== 'undefined') {
                    outTimes[key as keyof ScheduleWeekly['sendOutTimes']] = value.format('HH:mm');
                }
            }
            survey.schedule.sendOutTimes = outTimes;
        }
    }

    return survey;
}

export const surveysSlice = createSlice({
    name: 'surveys',
    initialState,
    reducers: {
        addNewSurvey(state, action: PayloadAction<Survey>) {
            if (state.surveys) {
                state.surveys = [...state.surveys || [], action.payload];
            }
            else {
                state.surveys = [action.payload];
            }
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getAllSurveys.fulfilled, (state, action) => {
            const formattedSurveys: Survey[] = action.payload.map(survey => {
                return formatSendOutTimeToDayJs(survey);
            });
            state.surveys = formattedSurveys;
        });

        builder.addCase(createNewSurvey.fulfilled, (state, action) => {
            action.payload = formatSendOutTimeToDayJs(action.payload);

            if (state.surveys) {
                state.surveys = [...state.surveys || [], action.payload];
            }
            else {
                state.surveys = [action.payload];
            }
        });
        builder.addCase(editSurvey.fulfilled, (state, action) => {
            if (state.surveys) {
                action.payload = formatSendOutTimeToDayJs(action.payload);
                state.surveys = [...state.surveys.filter(survey => survey.id !== action.payload.id), action.payload];
            }
        });
        builder.addCase(deleteSurvey.fulfilled, (state, action) => {

            if (state.surveys) {
                state.surveys = state.surveys.filter(survey => survey.id !== action.meta.arg);
            }
        });
        builder.addCase(createNewQuestion.fulfilled, (state, action) => {

            const { surveyId } = action.meta.arg;

            if (state.surveys) {
                const currentSurvey = state.surveys.find(survey => survey.id === surveyId);


                if (currentSurvey) {
                    currentSurvey.questions.push(action.payload.newQuestion);
                }
            }
        });
        builder.addCase(editQuestion.fulfilled, (state, action) => {
            const { surveyId } = action.meta.arg;

            if (state.surveys) {
                const currentSurvey = state.surveys.find(survey => survey.id === surveyId);

                if (currentSurvey) {
                    currentSurvey.questions = [...currentSurvey.questions
                        .filter(question => question.id !== action.payload.editedQuestion.id), action.payload.editedQuestion];
                }
            }
        });
        builder.addCase(deleteQuestion.fulfilled, (state, action) => {
            const { surveyId, questionId } = action.meta.arg;

            if (state.surveys) {
                const currentSurvey = state.surveys.find(survey => survey.id === surveyId);

                if (currentSurvey) {
                    currentSurvey.questions = currentSurvey.questions.filter(question => question.id !== questionId);
                }
            }
        });
        builder.addCase(launchSurvey.fulfilled, (state, action) => {
            state.surveyLaunch = action.payload;
        });
    }
});

interface EditSurveysInGroupParams {
    groupId: string;
    oldSurveys: Survey[];
    newSurveys: Survey[];
}

export const editSurveysInGroup = createAsyncThunk(
    'surveys/editSurveysInGroup',
    async function ({ groupId, newSurveys, oldSurveys }: EditSurveysInGroupParams, thunkApi) {

        const oldSurveysArray = oldSurveys.filter(x => newSurveys.find(y => y.id === x.id));
        const removeSurveysArray = oldSurveys.filter(x => !newSurveys.find(y => y.id === x.id));
        const newSurveysArray = newSurveys.filter(x => !oldSurveysArray.find(y => y.id === x.id) && !removeSurveysArray.find(y => y.id === x.id));


        const editedSurveysArray = newSurveys.filter(selected => {
            const prev = oldSurveysArray.find(old => old.id === selected.id);

            if (prev) {

                return JSON.stringify(prev) !== JSON.stringify(selected);
            }

            return false;
        });

        if (editedSurveysArray.length > 0) {
            editedSurveysArray.forEach(survey => {
                survey = formatSendOutTimeToString(survey);
                thunkApi.dispatch(editSurvey({ surveyId: survey.id, updateSurveyBody: survey }));
            });
        }


        if (removeSurveysArray.length > 0) {
            removeSurveysArray.forEach(survey => {
                thunkApi.dispatch(deleteSurvey(survey.id));
            });
        }

        if (newSurveysArray.length > 0) {
            newSurveysArray.forEach(survey => {
                survey = formatSendOutTimeToString(survey);
                const createSurveyBody = { 
                    title: survey.title, 
                    schedule: survey.schedule,
                    groupId: groupId, 
                    channelId: survey.channelId,
                    timeToAnswerInMinutes: survey.timeToAnswerInMinutes
                };
                thunkApi.dispatch(createNewSurvey({ createSurveyBody, newQuestions: survey.questions }));
            });
        }
    }
);

export const createNewSurvey = createAsyncThunk(
    'surveys/createNewSurvey',
    async function ({ createSurveyBody, newQuestions }: { createSurveyBody: CreateSurveyBody, newQuestions?: CreateQuestionBody[], }, thunkApi) {
        const newSurvey = await surveysService.createNewSurvey(createSurveyBody);

        newQuestions = newQuestions || [];

        for (const question of newQuestions) {
            const newQuestion = await questionsService.createNewQuestion(newSurvey.id, question);
            newSurvey.questions.push(newQuestion);
        }

        return newSurvey;
    }
);

export const getAllSurveys = createAsyncThunk(
    'surveys/getAllSurveys',
    async function () {
        const surveys = await surveysService.getAllSurveys();

        return surveys;
    }
);

interface EditSurveyParams {
    surveyId: string;
    updateSurveyBody: Survey;
}

export const editSurvey = createAsyncThunk(
    'surveys/editSurvey',
    async function ({ surveyId, updateSurveyBody }: EditSurveyParams, thunkApi) {

        const editedSurvey = await surveysService.editSurvey(surveyId, updateSurveyBody);
        thunkApi.dispatch(editQuestonsInSurvey({ surveyId, oldQuestions: editedSurvey.questions, newQuestions: updateSurveyBody.questions }));

        return editedSurvey;
    }
);

export const deleteSurvey = createAsyncThunk(
    'surveys/deleteSurvey',
    async function (surveyId: string) {
        await surveysService.deleteSurvey(surveyId);

    }
);

export const launchSurvey = createAsyncThunk(
    'surveys/launchSurvey',
    async function (launchSurveyBody: LaunchSurveyBody) {
        const surveyLaunch = await surveysService.launchSurvey(launchSurveyBody);

        return surveyLaunch;
    }
);

export const postSurveyAnswersBody = createAsyncThunk(
    'surveys/postSurveyAnswersBody',
    async function (postSurveyAnswersBody: PostSurveyAnswersBody) {
        await surveysService.postAnswers(postSurveyAnswersBody);
    }
);

export const { addNewSurvey } = surveysSlice.actions;

export default surveysSlice.reducer;