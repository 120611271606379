import { EmptyRespone } from '../../../domain/common/EmptyRespone';
import { CreateUserBody } from '../../../domain/users/models/CreateUserBody';
import { User } from '../../../domain/users/models/User';
import { UsersRepository } from '../../../domain/users/repository/UsersRepository';
import NewtorkService from '../NetworkService';

class UsersService extends NewtorkService implements UsersRepository {

    createNewUser(createUserBody: CreateUserBody): Promise<User> {
        return this.request('/users', 'POST', createUserBody);
    }

    getUsers(): Promise<User[]> {
        return this.request('/users', 'GET');
    }

    editUser(editedUser: User): Promise<EmptyRespone> {
        return this.request(`/users/${editedUser.id}`, 'PUT', editedUser);
    }

    deleteUser(userId: string): Promise<EmptyRespone> {
        return this.request(`/users/${userId}`, 'DELETE');
    }
}

export const usersService = new UsersService();