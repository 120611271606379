import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { groupsService } from '../data/network/services/GroupsService';
import { CreateGroupBody } from '../domain/groups/models/CreateGroupBody';
import { Group } from '../domain/groups/models/Group';
import { CreateQuestionBody } from '../domain/questions/models/CreateQuestionBody';
import { CreateSurveyBody } from '../domain/surveys/models/CreateSurveyBody';
import { AddUserBody } from '../domain/users/models/AddUserBody';
import { User } from '../domain/users/models/User';
import { RootState } from '../store/store';
import { createNewSurvey, formatSendOutTimeToString } from './SurveysSlice';
import { Survey } from '../domain/surveys/models/Survey';
import { NotificationProps } from '../domain/common/NotificationProps';

interface GroupsSliceState {
    groups: Group[];
}

const initialState: GroupsSliceState = {
    groups: []
};

export const groupsSlice = createSlice({
    name: 'groups',
    initialState,
    reducers: {

    },
    extraReducers: (builder) => {
        builder.addCase(getGroups.fulfilled, (state, action) => {
            state.groups = action.payload;
        });
    }
});

interface CreateNewGroupParams {
    createGroupBody: CreateGroupBody;
    selectedUsers: AddUserBody[];
    newSurveys?: Omit<CreateSurveyBody & { questions?: CreateQuestionBody[], }, 'groupId'>[];
}

export const createNewGroup = createAsyncThunk(
    'groups/createNewGroup',
    async function ({ createGroupBody, selectedUsers, newSurveys }: CreateNewGroupParams, thunkApi) {
        const newGroup = await groupsService.createNewGroup(createGroupBody);

        if (selectedUsers && selectedUsers.length > 0) {
            const { usersReducer } = thunkApi.getState() as RootState;

            await Promise.all(
                selectedUsers.map(selectedUser => {
                    const groupId = newGroup.id;
                    const addUserBody: AddUserBody = {
                        userId: selectedUser.userId
                    };

                    return groupsService.addUserInGroup(groupId, addUserBody).then(() => {
                        newGroup.users.push(usersReducer.users!.find(user => user.id === addUserBody.userId)!);
                    });
                })
            );
        }

        if (newSurveys) {

            newSurveys.forEach(newSurvey => {
                newSurvey = formatSendOutTimeToString(newSurvey as Survey);
                const createSurveyBody = { ...newSurvey, groupId: newGroup.id };
                thunkApi.dispatch(createNewSurvey({ createSurveyBody, newQuestions: newSurvey.questions }));
            });
        }


        return { newGroup };
    }
);


export const getGroups = createAsyncThunk(
    'groups/getGroups',
    async function () {
        const groups = await groupsService.getAllGroups();

        return groups;
    }
);

export const deleteGroup = createAsyncThunk(
    'groups/deleteGroup',
    async function ({ groupId }: { projectId: string, groupId: string, }) {
        await groupsService.deleteGroup(groupId);
    }
);

interface EditUsersInGroupParams {
    projectId: string;
    groupId: string;
    selectedUsers: User[];
    currentUsers: User[];
}

export const editUsersInGroup = createAsyncThunk(
    'groups/editUsersInGroup',
    async function (editUsersInGroupParams: EditUsersInGroupParams, thunkApi) {
        const { projectId, groupId, selectedUsers, currentUsers } = editUsersInGroupParams;

        const oldUsersArray = currentUsers.filter(x => selectedUsers.find(y => y.id === x.id));
        const removeUsersArray = currentUsers.filter(x => !selectedUsers.find(y => y.id === x.id));
        const newUsersArray = selectedUsers.filter(x => !oldUsersArray.find(y => y.id === x.id) && !removeUsersArray.find(y => y.id === x.id));

        let notification: NotificationProps = {
            notificationBody: 'Список пользователей в группе успешно отредактирован',
            notificationType: 'success'
        };

        const promiseSettledResultRemoveUsers = await thunkApi.dispatch(
            removeUserFromGroup(
                {
                    projectId, groupId: groupId,
                    users: removeUsersArray
                }
            )
        ).unwrap();

        promiseSettledResultRemoveUsers.forEach((res, index) => {
            if (res.status === 'rejected') {
                oldUsersArray.push(removeUsersArray[index]);
            }
        });

        const promiseSettledResultAddUsers = await thunkApi.dispatch(
            addUserInGroup(
                {
                    projectId, groupId: groupId,
                    users: newUsersArray
                }
            )
        ).unwrap();


        promiseSettledResultAddUsers.forEach((res, index) => {
            if (res.status === 'rejected') {
                newUsersArray.splice(index, 1);
            }
        });


        const settledResults = [...promiseSettledResultRemoveUsers, ...promiseSettledResultAddUsers];
        const isResHasErrors = settledResults.find(res => res.status === 'rejected');
        const isResHasSuccess = settledResults.find(res => res.status === 'fulfilled');

        if (isResHasErrors || isResHasSuccess) {

            if (isResHasErrors && isResHasSuccess) {
                notification = {
                    notificationBody: 'Список пользователей в группе отредактирован с ошибками',
                    notificationType: 'warning'
                };
            }
            else if (isResHasErrors && !isResHasSuccess) {
                notification = {
                    notificationBody: 'Список пользователей в группе не удалось отредактировать',
                    notificationType: 'error'
                };
            }

            return { users: [...oldUsersArray, ...newUsersArray], notification };
        }

        return { users: [...oldUsersArray, ...newUsersArray] };
    }
);

interface AddOrRemoveUserInGroupParams {
    projectId: string;
    groupId: string;
    users: User[];
}

export const addUserInGroup = createAsyncThunk(
    'groups/addUserInGroup',
    async function ({ groupId, users }: AddOrRemoveUserInGroupParams) {


        const promiseSettledResultAddUsers = await Promise.allSettled(
            users.map(user => {
                return groupsService.addUserInGroup(groupId, { userId: user.id });
            })
        );

        return promiseSettledResultAddUsers;
    }
);

export const removeUserFromGroup = createAsyncThunk(
    'groups/removeUserFromGroup',
    async function ({ groupId, users }: AddOrRemoveUserInGroupParams) {


        const promiseSettledResultRemoveUsers = await Promise.allSettled(
            users.map(user => {
                return groupsService.removeUserFromGroup(groupId, user.id);
            })
        );

        return promiseSettledResultRemoveUsers;
    }
);

export default groupsSlice.reducer;