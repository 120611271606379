import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse, RawAxiosRequestHeaders } from 'axios';
import { AuthResponse } from '../../domain/auth/models/AuthResponse';
import { HTTPMethod } from '../common/HTTPMethod';
import NetworkURL from '../common/NetworkURL';
import { Error } from '../../domain/common/Error';
import { dataStore } from '../dataStore/WebStorageDataStore';

export default class NetworkService {
    private instance: AxiosInstance;
    constructor() {
        this.instance = axios.create({
            baseURL: NetworkURL.apiBaseURL
        });
        this.setupInterceptors();
    }

    request<T>(url: string, method: HTTPMethod, parameters: Object | null = null, headers?: RawAxiosRequestHeaders): Promise<T> {
        let config: AxiosRequestConfig = {
            url: url,
            method: method,
            headers: headers
        };

        if (method === 'GET') {
            config.params = parameters;
        } else {
            config.data = parameters;
        }

        return this.instance.request(config).then(response => {
            return Promise.resolve(response.data);
        }).catch(error => {
            let customError = this.createError(error.response);

            return Promise.reject(customError);
        });
    }

    private createError(response: AxiosResponse): Error {
        const newError: Error = { message: response.data.message, code: response.data.code };

        return newError;
    }

    private setupInterceptors() {
        this.setupRequestInterceptor();
        this.setupResponseInterceptor();
    }

    private setupRequestInterceptor() {
        this.instance.interceptors.request.use(config => {
            if (dataStore.accessToken) {
                if (!config) {
                    config = {};
                }

                if (!config.headers) {
                    config.headers = {};
                }

                if (config.url === '/auth/refresh') {
                    config.headers.Authorization = `Bearer ${dataStore.refreshToken}`;
                }
                else {
                    config.headers.Authorization = `Bearer ${dataStore.accessToken}`;
                }
            }


            return config;
        }, error => {
            return Promise.reject(error);
        });
    }

    private setupResponseInterceptor() {
        let interceptor = this.instance.interceptors.response.use(response => {
            return response;
        }, error => {
            const originalRequest = error.config;

            if (error.response.status === 401 && error.config.url !== '/auth/login') {
                this.instance.interceptors.response.eject(interceptor);

                return this.refreshToken().then(token => {
                    dataStore.accessToken = token.accessToken;
                    dataStore.refreshToken = token.refreshToken;

                    return this.instance(originalRequest);
                }).catch(error => {
                    this.clearTokens();

                    return Promise.reject(error);
                }).finally(() => {
                    this.setupResponseInterceptor();
                });

            }


            return Promise.reject(error);
        });
    }

    private async refreshToken(): Promise<AuthResponse> {
        return this.request<AuthResponse>('/auth/refresh', 'POST');
    }

    private clearTokens() {
        dataStore.accessToken = null;
        dataStore.refreshToken = null;
        window.location.href = '/';
    }
}